export class Product {
    productId: number;
    productName: string;
    displayName: string;
    description: string;
    platformId: number;
    diagramTypeId: number;
    businessOwnerId: number;
    leadSMEId: number;
    refreshFrequency: string;
    solutionOverview: string;
    businessChallenge: string;
    businessGroupCoverageCodes: string;
    businessValue: string;
    rowLimitations: string;
    geographyInScope: string;
    geoCoverageLevelIds: string;
    geoCoverageValueCodes: string;
    expNumOfUsers: number;
    expNumOfConcUsers: number;
    dataClassification: string;
    expectedResponse: string;
    deliveryDateExpected: string;
    deliveryDateCommitted: string;
    diagramNotes: string;
    smeNotesProduct: string;
    dappIssueKey: string;

    loadDate: string;
    refreshDate: string;
    deliveryDateExpectedAsText: string;
    deliveryDateCommittedAsText: string;
}
