var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "product-measure-groups" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-2" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        dark: "",
                        disabled: _vm.isBatchLocked,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.add()
                        },
                      },
                    },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.items,
                      "item-key": "id",
                      options: _vm.options,
                      "server-items-length": _vm.totalItems,
                      loading: _vm.loading,
                      "loading-text": "Loading... Please wait",
                      "footer-props": {
                        showFirstLastPage: true,
                        itemsPerPageOptions: [20, 50, 100],
                      },
                      "fixed-header": "",
                      dense: "",
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function () {
                          return [
                            _c(
                              "v-col",
                              { attrs: { md: "12", xs: "12" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { md: "6", xs: "6" } },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Search by Measure Group",
                                            items:
                                              _vm.filteredLookupData
                                                .measureGroups,
                                            "item-text": "text",
                                            "item-value": "id",
                                            placeholder:
                                              "Search by Measure Group",
                                            outlined: "",
                                            dense: "",
                                            clearable: "",
                                            multiple: "",
                                          },
                                          on: { change: _vm.getItemsByOptions },
                                          model: {
                                            value:
                                              _vm.filterOptions.measureGroupIds,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filterOptions,
                                                "measureGroupIds",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "filterOptions.measureGroupIds",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "item.measureGroupName",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "v-chip--clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.editItem(item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.measureGroupName) +
                                    " [" +
                                    _vm._s(item.measureGroupId) +
                                    "]"
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.targetObjectList",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "v-chip--clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.editItem(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.targetObjectList))]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.loadDateText",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "v-chip--clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.editItem(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.loadDateText))]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  "aria-label": "Edit",
                                  "aria-hidden": "false",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editItem(item)
                                  },
                                },
                              },
                              [_vm._v("mdi-pencil")]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  "aria-label": "Delete",
                                  "aria-hidden": "false",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item)
                                  },
                                },
                              },
                              [_vm._v("mdi-trash-can-outline")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _c(
                              "v-alert",
                              {
                                staticClass: "mt-4",
                                attrs: {
                                  type: "warning",
                                  dense: "",
                                  border: "left",
                                },
                              },
                              [_vm._v(" Sorry, no data to display ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "no-results",
                        fn: function () {
                          return [
                            _c(
                              "v-alert",
                              {
                                staticClass: "mt-4",
                                attrs: {
                                  type: "warning",
                                  dense: "",
                                  border: "left",
                                },
                              },
                              [_vm._v(" Sorry, no results found ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "v-overlay",
                    {
                      staticClass: "align-center justify-center",
                      attrs: { value: _vm.overlay },
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", size: "64" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        fullscreen: "",
                        scrollable: "",
                        transition: "dialog-bottom-transition",
                      },
                      model: {
                        value: _vm.editDialog,
                        callback: function ($$v) {
                          _vm.editDialog = $$v
                        },
                        expression: "editDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "mb-5 flex-grow-0",
                              attrs: { dark: "", color: "primary" },
                            },
                            [
                              _vm.isEdit
                                ? _c("v-toolbar-title", [
                                    _vm._v(
                                      "Edit - " +
                                        _vm._s(_vm.editedItem.productName) +
                                        " [" +
                                        _vm._s(_vm.editedItem.productId) +
                                        "] - " +
                                        _vm._s(
                                          _vm.editedItem.measureGroupName
                                        ) +
                                        " [" +
                                        _vm._s(_vm.editedItem.measureGroupId) +
                                        "]"
                                    ),
                                  ])
                                : _c("v-toolbar-title", [
                                    _vm._v(
                                      "Add Measure Group to " +
                                        _vm._s(_vm.editedItem.productName) +
                                        " [" +
                                        _vm._s(_vm.editedItem.productId) +
                                        "]"
                                    ),
                                  ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", dark: "" },
                                  on: { click: _vm.closeEditDialog },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "aria-label": "Close",
                                        "aria-hidden": "false",
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "productMeasureGroupEditForm",
                                  model: {
                                    value: _vm.editItemIsValid,
                                    callback: function ($$v) {
                                      _vm.editItemIsValid = $$v
                                    },
                                    expression: "editItemIsValid",
                                  },
                                },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _vm.isMeasureGroupExisting
                                                ? _c(
                                                    "v-alert",
                                                    {
                                                      staticClass: "mb-6",
                                                      attrs: {
                                                        border: "bottom",
                                                        color: "red darken-2",
                                                        dark: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "The Measure Group is already being used by this Product, please select a different Measure Group."
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              !_vm.isEdit
                                                ? _c("v-autocomplete", {
                                                    staticClass: "mb-4",
                                                    attrs: {
                                                      label: "Measure Group",
                                                      items:
                                                        _vm.lookupData
                                                          .measureGroups,
                                                      "item-text": "text",
                                                      "item-value": "id",
                                                      placeholder:
                                                        "Select a Measure Group",
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Select a Measure Group",
                                                      ],
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      change: _vm.getExists,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _vm._v(
                                                                " Measure Group "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "red--text",
                                                                },
                                                                [_vm._v("*")]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2217107489
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.editedItem
                                                          .measureGroupId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editedItem,
                                                          "measureGroupId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editedItem.measureGroupId",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-textarea", {
                                                staticClass: "mb-4",
                                                attrs: {
                                                  label: "Description",
                                                  maxlength: 10000,
                                                  counter: "",
                                                  outlined: "",
                                                  "auto-grow": "",
                                                  clearable: "",
                                                  dense: "",
                                                  hint: "A brief description of the measure. This should be in readable English.",
                                                  "persistent-hint": "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem.description,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem.description",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-container",
                                [
                                  !_vm.editItemIsValid ||
                                  _vm.isMeasureGroupExisting
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    color: "pink darken-1",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "All fields must be valid and ones marked with "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "red--text",
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(
                                                    " are required. Button has been disabled until completed."
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-uppercase",
                                              attrs: {
                                                block: "",
                                                tile: "",
                                                outlined: "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click: _vm.closeEditDialog,
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _vm.isEdit
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    disabled:
                                                      !_vm.editItemIsValid ||
                                                      _vm.isMeasureGroupExisting,
                                                    color: "secondary",
                                                  },
                                                  on: { click: _vm.update },
                                                },
                                                [_vm._v("Update")]
                                              )
                                            : _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    disabled:
                                                      !_vm.editItemIsValid ||
                                                      _vm.isMeasureGroupExisting,
                                                    color: "secondary",
                                                  },
                                                  on: { click: _vm.create },
                                                },
                                                [_vm._v("Create")]
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        width: "500",
                        transition: "dialog-bottom-transition",
                      },
                      model: {
                        value: _vm.deleteDialog,
                        callback: function ($$v) {
                          _vm.deleteDialog = $$v
                        },
                        expression: "deleteDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "mb-4",
                              attrs: { dark: "", color: "primary" },
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                [
                                  _c("v-icon", [_vm._v("mdi-warning")]),
                                  _vm._v("Delete"),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", dark: "" },
                                  on: { click: _vm.closeDeleteDialog },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "aria-label": "Close",
                                        "aria-hidden": "false",
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            { staticClass: "font-weight-bold text-md-body-1" },
                            [_vm._v("Are you sure you want to delete?")]
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-uppercase",
                                              attrs: {
                                                block: "",
                                                tile: "",
                                                outlined: "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click: _vm.closeDeleteDialog,
                                              },
                                            },
                                            [_vm._v("No")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-uppercase",
                                              attrs: {
                                                block: "",
                                                tile: "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click: _vm.deleteItemConfirm,
                                              },
                                            },
                                            [_vm._v("Yes")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }