var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "subject-area-form" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-form",
            {
              ref: "subjectAreaForm",
              model: {
                value: _vm.editItemIsValid,
                callback: function ($$v) {
                  _vm.editItemIsValid = $$v
                },
                expression: "editItemIsValid",
              },
            },
            [
              !_vm.editItemIsValid
                ? _c("v-row", { attrs: { "no-gutters": "" } })
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Subject Area Name",
                          maxlength: 100,
                          rules: [
                            (v) => !!v || "Subject Area Name is required",
                          ],
                          required: "",
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          hint: "The name of the Subject Area should be unique.",
                          "persistent-hint": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(" Subject Area Name "),
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.subjectAreaName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "subjectAreaName", $$v)
                          },
                          expression: "editedItem.subjectAreaName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-textarea", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Diagram Notes",
                          maxlength: 8000,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          "auto-grow": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [_vm._v(" Diagram Notes ")]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.diagramNotes,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "diagramNotes", $$v)
                          },
                          expression: "editedItem.diagramNotes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isBatchLocked
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            color: "pink darken-1",
                            outlined: "",
                            dense: "",
                          },
                        },
                        [
                          _vm._v(
                            " There is a scheduled job in progress that must be completed. Please wait and try refreshing later. Saving is currently disabled."
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isNameExisting
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-6",
                          attrs: {
                            border: "bottom",
                            color: "red darken-2",
                            dark: "",
                          },
                        },
                        [
                          _vm._v(
                            "The Subject Area Name is already exists, please try a different Name."
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.isCreateState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            outlined: "",
                            href: "/subjectAreas",
                            color: "secondary",
                          },
                        },
                        [_vm._v("Back")]
                      )
                    : _vm._e(),
                  _vm.isEditState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            outlined: "",
                            href: "/subjectAreas",
                            color: "secondary",
                          },
                        },
                        [_vm._v("Cancel")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _vm.isCreateState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            disabled: !_vm.editItemIsValid || _vm.isBatchLocked,
                            color: "secondary",
                          },
                          on: { click: _vm.create },
                        },
                        [_vm._v("Create")]
                      )
                    : _vm._e(),
                  _vm.isEditState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            disabled: !_vm.editItemIsValid || _vm.isBatchLocked,
                            color: "secondary",
                          },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Save")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          staticClass: "align-center justify-center",
          attrs: { value: _vm.overlay },
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }