var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "users" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.users,
                      "item-key": "emailAddress",
                      options: _vm.options,
                      "server-items-length": _vm.totalItems,
                      loading: _vm.loading,
                      "loading-text": "Loading... Please wait",
                      "footer-props": {
                        showFirstLastPage: true,
                        itemsPerPageOptions: [20, 50, 100],
                      },
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function () {
                          return [
                            _c(
                              "v-toolbar",
                              { attrs: { flat: "" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "mx-4",
                                  attrs: { label: "Search" },
                                  model: {
                                    value: _vm.search,
                                    callback: function ($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search",
                                  },
                                }),
                                _c("v-spacer"),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: {
                                      width: "800",
                                      transition: "dialog-bottom-transition",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("Create User")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.dialog,
                                      callback: function ($$v) {
                                        _vm.dialog = $$v
                                      },
                                      expression: "dialog",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-toolbar",
                                          {
                                            staticClass: "mb-5",
                                            attrs: {
                                              dark: "",
                                              color: "primary",
                                            },
                                          },
                                          [
                                            _c("v-toolbar-title", [
                                              _vm._v(_vm._s(_vm.formTitle)),
                                            ]),
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { icon: "", dark: "" },
                                                on: { click: _vm.close },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      "aria-label": "Close",
                                                      "aria-hidden": "false",
                                                    },
                                                  },
                                                  [_vm._v("mdi-close")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          { staticClass: "pb-0" },
                                          [
                                            _c(
                                              "v-form",
                                              {
                                                model: {
                                                  value: _vm.editedItem.isValid,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      "isValid",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem.isValid",
                                                },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "error-messages":
                                                      _vm.emailAlreadyExistsErrorMessage,
                                                    label: "Email Address",
                                                    rules: _vm.emailRules,
                                                    maxlength: 320,
                                                    type: "",
                                                    required: "",
                                                    outlined: "",
                                                  },
                                                  on: {
                                                    change: _vm.emailChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editedItem
                                                        .emailAddress,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editedItem,
                                                        "emailAddress",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editedItem.emailAddress",
                                                  },
                                                }),
                                                _c("v-text-field", {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    label: "Username",
                                                    rules: [
                                                      (v) =>
                                                        !!v ||
                                                        "Username is required",
                                                    ],
                                                    maxlength: 250,
                                                    disabled: "",
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editedItem.userName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editedItem,
                                                        "userName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editedItem.userName",
                                                  },
                                                }),
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    label: "Roles",
                                                    items: _vm.userRoles,
                                                    "item-text": "text",
                                                    "item-value": "id",
                                                    placeholder:
                                                      "Select User Roles",
                                                    rules: _vm.userRoleRules,
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    clearable: "",
                                                    multiple: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editedItem
                                                        .userRoleIds,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editedItem,
                                                        "userRoleIds",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editedItem.userRoleIds",
                                                  },
                                                }),
                                                _c("v-text-field", {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    label: "Designation",
                                                    rules: [
                                                      (v) =>
                                                        !!v ||
                                                        "Designation is required",
                                                    ],
                                                    maxlength: 50,
                                                    required: "",
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value: _vm.editedItem.role,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editedItem,
                                                        "role",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editedItem.role",
                                                  },
                                                }),
                                                _c("v-text-field", {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    label: "Business Function",
                                                    rules: [
                                                      (v) =>
                                                        !!v ||
                                                        "Business Function is required",
                                                    ],
                                                    maxlength: 50,
                                                    required: "",
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editedItem
                                                        .businessFunction,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editedItem,
                                                        "businessFunction",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editedItem.businessFunction",
                                                  },
                                                }),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c("v-checkbox", {
                                                          staticClass: "mt-2",
                                                          attrs: {
                                                            label: "Active",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedItem
                                                                .active,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedItem,
                                                                "active",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "editedItem.active",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c("v-checkbox", {
                                                          staticClass: "mt-2",
                                                          attrs: {
                                                            label:
                                                              "Prefer Email Notifications",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedItem
                                                                .receiveEmailYN,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedItem,
                                                                "receiveEmailYN",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "editedItem.receiveEmailYN",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c(
                                              "v-container",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "text-uppercase",
                                                            attrs: {
                                                              block: "",
                                                              tile: "",
                                                              outlined: "",
                                                              color:
                                                                "secondary",
                                                            },
                                                            on: {
                                                              click: _vm.close,
                                                            },
                                                          },
                                                          [_vm._v("Cancel")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "text-uppercase",
                                                            attrs: {
                                                              block: "",
                                                              tile: "",
                                                              disabled:
                                                                !_vm.editedItem
                                                                  .isValid,
                                                              color:
                                                                "secondary",
                                                            },
                                                            on: {
                                                              click: _vm.save,
                                                            },
                                                          },
                                                          [_vm._v("Save")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("v-spacer"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: {
                                      width: "500",
                                      transition: "dialog-bottom-transition",
                                    },
                                    model: {
                                      value: _vm.dialogDelete,
                                      callback: function ($$v) {
                                        _vm.dialogDelete = $$v
                                      },
                                      expression: "dialogDelete",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-toolbar",
                                          {
                                            staticClass: "mb-4",
                                            attrs: {
                                              dark: "",
                                              color: "primary",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-toolbar-title",
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-warning"),
                                                ]),
                                                _vm._v("Delete"),
                                              ],
                                              1
                                            ),
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { icon: "", dark: "" },
                                                on: { click: _vm.closeDelete },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      "aria-label": "Close",
                                                      "aria-hidden": "false",
                                                    },
                                                  },
                                                  [_vm._v("mdi-close")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          {
                                            staticClass:
                                              "font-weight-bold text-md-body-1",
                                          },
                                          [
                                            _vm._v(
                                              "Are you sure you want to delete?"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c(
                                              "v-container",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "text-uppercase",
                                                            attrs: {
                                                              block: "",
                                                              tile: "",
                                                              outlined: "",
                                                              color:
                                                                "secondary",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.closeDelete,
                                                            },
                                                          },
                                                          [_vm._v("Cancel")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "text-uppercase",
                                                            attrs: {
                                                              block: "",
                                                              tile: "",
                                                              color:
                                                                "secondary",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.deleteItemConfirm,
                                                            },
                                                          },
                                                          [_vm._v("Save")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "item.userName",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "v-chip--clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.editItem(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.userName))]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.emailAddress",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "v-chip--clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.editItem(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.emailAddress))]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.active",
                        fn: function ({ item }) {
                          return [
                            _c("v-simple-checkbox", {
                              attrs: { disabled: "" },
                              model: {
                                value: item.active,
                                callback: function ($$v) {
                                  _vm.$set(item, "active", $$v)
                                },
                                expression: "item.active",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  "aria-label": "Edit",
                                  "aria-hidden": "false",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editItem(item)
                                  },
                                },
                              },
                              [_vm._v("mdi-pencil")]
                            ),
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  "aria-label": "Delete",
                                  "aria-hidden": "false",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item)
                                  },
                                },
                              },
                              [_vm._v("mdi-trash-can-outline")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _c(
                              "v-alert",
                              {
                                staticClass: "mt-4",
                                attrs: {
                                  type: "warning",
                                  dense: "",
                                  border: "left",
                                },
                              },
                              [_vm._v(" Sorry, no data to display ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "no-results",
                        fn: function () {
                          return [
                            _c(
                              "v-alert",
                              {
                                staticClass: "mt-4",
                                attrs: {
                                  type: "warning",
                                  dense: "",
                                  border: "left",
                                },
                              },
                              [_vm._v(" Sorry, no results found ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }