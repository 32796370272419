var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "platforms-form" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-form",
            {
              ref: "platformForm",
              model: {
                value: _vm.editItemIsValid,
                callback: function ($$v) {
                  _vm.editItemIsValid = $$v
                },
                expression: "editItemIsValid",
              },
            },
            [
              !_vm.editItemIsValid
                ? _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                color: "pink darken-1",
                                outlined: "",
                                dense: "",
                              },
                            },
                            [
                              _vm._v(
                                "All data provided must be valid. Any fields marked with "
                              ),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" are required."),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Platform Name",
                          maxlength: 100,
                          rules: [(v) => !!v || "Platform Name is required"],
                          required: "",
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          hint: "The name of the Platform should be unique.",
                          "persistent-hint": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(" Platform Name "),
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.platformName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "platformName", $$v)
                          },
                          expression: "editedItem.platformName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Catalogue Platform Name",
                          maxlength: 100,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.editedItem.cataloguePlatformName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editedItem,
                              "cataloguePlatformName",
                              $$v
                            )
                          },
                          expression: "editedItem.cataloguePlatformName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-textarea", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Catalogue Platform Description",
                          maxlength: 1000,
                          counter: "",
                          outlined: "",
                          "auto-grow": "",
                          clearable: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.editedItem.cataloguePlatformDescription,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editedItem,
                              "cataloguePlatformDescription",
                              $$v
                            )
                          },
                          expression: "editedItem.cataloguePlatformDescription",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-textarea", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Catalogue Platform RootPath",
                          maxlength: 1000,
                          counter: "",
                          outlined: "",
                          "auto-grow": "",
                          clearable: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.editedItem.cataloguePlatformRootPath,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editedItem,
                              "cataloguePlatformRootPath",
                              $$v
                            )
                          },
                          expression: "editedItem.cataloguePlatformRootPath",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Catalogue Integration Type",
                          maxlength: 100,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.editedItem.catalogueIntegrationType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editedItem,
                              "catalogueIntegrationType",
                              $$v
                            )
                          },
                          expression: "editedItem.catalogueIntegrationType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Global/Regional",
                          items: _vm.lookupData.globalOrRegionals,
                          "item-text": "text",
                          "item-value": "id",
                          rules: [(v) => !!v || "Select a Global/Regional"],
                          placeholder: "Select a Global/Regional",
                          required: "",
                          outlined: "",
                          dense: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(" Global/Regional "),
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.globalOrRegional,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "globalOrRegional", $$v)
                          },
                          expression: "editedItem.globalOrRegional",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Data Coverage",
                          maxlength: 100,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.editedItem.dataCoverage,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "dataCoverage", $$v)
                          },
                          expression: "editedItem.dataCoverage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Data Owner",
                          items: _vm.lookupData.dataOwners,
                          "item-text": "text",
                          "item-value": "id",
                          rules: [(v) => !!v || "Select a Data Owner"],
                          placeholder: "Select a Data Owner",
                          required: "",
                          outlined: "",
                          dense: "",
                          hint: "Who is the Data Owner of the data, select from dropdown.",
                          "persistent-hint": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(" Data Owner "),
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.dataOwnerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "dataOwnerId", $$v)
                          },
                          expression: "editedItem.dataOwnerId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Internal/External",
                          items: _vm.lookupData.internalExternals,
                          "item-text": "text",
                          "item-value": "id",
                          rules: [(v) => !!v || "Select Internal/External"],
                          placeholder: "Select Internal/External",
                          required: "",
                          outlined: "",
                          dense: "",
                          hint: "",
                          "persistent-hint": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(" Internal/External "),
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.internalExternal,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "internalExternal", $$v)
                          },
                          expression: "editedItem.internalExternal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Platform Level",
                          maxlength: 9,
                          rules: _vm.numberRule,
                          counter: "",
                          outlined: "",
                          clearable: "",
                          dense: "",
                          hint: "Whole Number input: Examples 2, 10, 100",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.platformLevel,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "platformLevel", $$v)
                          },
                          expression: "editedItem.platformLevel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Platform Group",
                          maxlength: 100,
                          counter: "",
                          outlined: "",
                          clearable: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.editedItem.platformGroup,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "platformGroup", $$v)
                          },
                          expression: "editedItem.platformGroup",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Platform Layer",
                          items: _vm.lookupData.platformLayers,
                          "item-text": "text",
                          "item-value": "id",
                          rules: [(v) => !!v || "Select a Platform Layer"],
                          placeholder: "Select a Platform Layer",
                          required: "",
                          outlined: "",
                          dense: "",
                          hint: "",
                          "persistent-hint": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(" Platform Layer "),
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.platformLayer,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "platformLayer", $$v)
                          },
                          expression: "editedItem.platformLayer",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Platform Type",
                          maxlength: 50,
                          counter: "",
                          outlined: "",
                          clearable: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.editedItem.platformType,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "platformType", $$v)
                          },
                          expression: "editedItem.platformType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Platform Region",
                          maxlength: 100,
                          counter: "",
                          outlined: "",
                          clearable: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.editedItem.platformRegion,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "platformRegion", $$v)
                          },
                          expression: "editedItem.platformRegion",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-textarea", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Platform Description",
                          maxlength: 1000,
                          counter: "",
                          outlined: "",
                          "auto-grow": "",
                          clearable: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.editedItem.platformDescription,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "platformDescription", $$v)
                          },
                          expression: "editedItem.platformDescription",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-textarea", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Source SPOC",
                          maxlength: 1000,
                          counter: "",
                          outlined: "",
                          "auto-grow": "",
                          clearable: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.editedItem.sourceSpoc,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "sourceSpoc", $$v)
                          },
                          expression: "editedItem.sourceSpoc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-textarea", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Source Escalation Contact",
                          maxlength: 1000,
                          counter: "",
                          outlined: "",
                          "auto-grow": "",
                          clearable: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.editedItem.sourceEscalationContact,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editedItem,
                              "sourceEscalationContact",
                              $$v
                            )
                          },
                          expression: "editedItem.sourceEscalationContact",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isBatchLocked
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            color: "pink darken-1",
                            outlined: "",
                            dense: "",
                          },
                        },
                        [
                          _vm._v(
                            " There is a scheduled job in progress that must be completed. Please wait and try refreshing later. Saving is currently disabled."
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isNameExisting
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-6",
                          attrs: {
                            border: "bottom",
                            color: "red darken-2",
                            dark: "",
                          },
                        },
                        [
                          _vm._v(
                            "The Platform Name is already being used by another Platform, please try a different Platform Name."
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.editItemIsValid
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            color: "pink darken-1",
                            outlined: "",
                            dense: "",
                          },
                        },
                        [
                          _vm._v(
                            "All data provided must be valid. Any fields marked with "
                          ),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(" are required. "),
                          _vm.isEditState
                            ? _c("span", [_vm._v("'Save'")])
                            : _vm._e(),
                          _vm.isCreateState
                            ? _c("span", [_vm._v("'Create'")])
                            : _vm._e(),
                          _vm._v(" button has been disabled until completed."),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.isCreateState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            outlined: "",
                            href: "/admin/platforms",
                            color: "secondary",
                          },
                        },
                        [_vm._v("Back")]
                      )
                    : _vm._e(),
                  _vm.isEditState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            outlined: "",
                            color: "secondary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getItem(_vm.platformId)
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _vm.isCreateState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            disabled: !_vm.editItemIsValid || _vm.isBatchLocked,
                            color: "secondary",
                          },
                          on: { click: _vm.create },
                        },
                        [_vm._v("Create")]
                      )
                    : _vm._e(),
                  _vm.isEditState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            disabled: !_vm.editItemIsValid || _vm.isBatchLocked,
                            color: "secondary",
                          },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Save")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          staticClass: "align-center justify-center",
          attrs: { value: _vm.overlay },
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }