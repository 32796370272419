var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "platforms-create" },
    [
      _c("v-breadcrumbs", {
        attrs: { items: _vm.breadcrumbItems },
        scopedSlots: _vm._u([
          {
            key: "divider",
            fn: function () {
              return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "h1",
                  {
                    staticClass:
                      "text-h4 font-weight-bold text-left primary--text pb-4 float-left",
                  },
                  [_vm._v("Create a Platform")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("platforms-form", {
                    attrs: { platformId: 0, formState: _vm.formState },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }