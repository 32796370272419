var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "product-dimensions" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-2" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        dark: "",
                        disabled: _vm.isBatchLocked,
                      },
                      on: { click: _vm.add },
                    },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.items,
                      "item-key": "id",
                      options: _vm.options,
                      "server-items-length": _vm.totalItems,
                      loading: _vm.loading,
                      "loading-text": "Loading... Please wait",
                      "footer-props": {
                        showFirstLastPage: true,
                        itemsPerPageOptions: [20, 50, 100],
                      },
                      "fixed-header": "",
                      dense: "",
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function () {
                          return [
                            _c(
                              "v-col",
                              { attrs: { md: "12", xs: "12" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { md: "6", xs: "6" } },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Search by Dimension",
                                            items:
                                              _vm.filteredLookupData.dimensions,
                                            "item-text": "text",
                                            "item-value": "id",
                                            placeholder: "Search by Dimension",
                                            outlined: "",
                                            dense: "",
                                            clearable: "",
                                            multiple: "",
                                          },
                                          on: { change: _vm.getItemsByOptions },
                                          model: {
                                            value:
                                              _vm.filterOptions.dimensionIds,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filterOptions,
                                                "dimensionIds",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "filterOptions.dimensionIds",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "item.dimensionName",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "v-chip--clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.editItem(item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.dimensionName) +
                                    " [" +
                                    _vm._s(item.dimensionId) +
                                    "] " +
                                    _vm._s(item.platformName) +
                                    " [" +
                                    _vm._s(item.platformId) +
                                    "]"
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  "aria-label": "Edit",
                                  "aria-hidden": "false",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editItem(item)
                                  },
                                },
                              },
                              [_vm._v("mdi-pencil")]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  "aria-label": "Delete",
                                  "aria-hidden": "false",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item)
                                  },
                                },
                              },
                              [_vm._v("mdi-trash-can-outline")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _c(
                              "v-alert",
                              {
                                staticClass: "mt-4",
                                attrs: {
                                  type: "warning",
                                  dense: "",
                                  border: "left",
                                },
                              },
                              [_vm._v(" Sorry, no data to display ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "no-results",
                        fn: function () {
                          return [
                            _c(
                              "v-alert",
                              {
                                staticClass: "mt-4",
                                attrs: {
                                  type: "warning",
                                  dense: "",
                                  border: "left",
                                },
                              },
                              [_vm._v(" Sorry, no results found ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        fullscreen: "",
                        scrollable: "",
                        transition: "dialog-bottom-transition",
                      },
                      model: {
                        value: _vm.editDialog,
                        callback: function ($$v) {
                          _vm.editDialog = $$v
                        },
                        expression: "editDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "mb-5 flex-grow-0",
                              attrs: { dark: "", color: "primary" },
                            },
                            [
                              _vm.isEdit
                                ? _c("v-toolbar-title", [
                                    _vm._v(
                                      "Edit - " +
                                        _vm._s(_vm.editedItem.productName) +
                                        " [" +
                                        _vm._s(_vm.editedItem.productId) +
                                        "] - " +
                                        _vm._s(_vm.editedItem.dimensionName) +
                                        " [" +
                                        _vm._s(_vm.editedItem.dimensionId) +
                                        "] " +
                                        _vm._s(_vm.editedItem.platformName) +
                                        " [" +
                                        _vm._s(_vm.editedItem.platformId) +
                                        "]"
                                    ),
                                  ])
                                : _c("v-toolbar-title", [
                                    _vm._v(
                                      "Add Dimension to " +
                                        _vm._s(_vm.editedItem.productName) +
                                        " [" +
                                        _vm._s(_vm.editedItem.productId) +
                                        "]"
                                    ),
                                  ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", dark: "" },
                                  on: { click: _vm.closeEditDialog },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "aria-label": "Close",
                                        "aria-hidden": "false",
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "v-form",
                                { ref: "productDimensionEditForm" },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      !_vm.isDimensionSelected
                                        ? _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      attrs: {
                                                        color: "pink darken-1",
                                                        outlined: "",
                                                        dense: "",
                                                      },
                                                    },
                                                    [
                                                      _c("ul", [
                                                        _c("li", [
                                                          _vm._v(
                                                            "Please select a Dimension."
                                                          ),
                                                        ]),
                                                        _c("li", [
                                                          _vm._v(
                                                            "Dimensions already assigned to this Product are not listed."
                                                          ),
                                                        ]),
                                                        _c("li", [
                                                          _vm._v(
                                                            "A Dimension must have a Star Schema Object Relation to a Target Object of a Measure Group in this Product before it can be selected below."
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              !_vm.isEdit
                                                ? _c("v-autocomplete", {
                                                    staticClass: "mb-4",
                                                    attrs: {
                                                      label: "Dimension",
                                                      items:
                                                        _vm.dimensionLookup,
                                                      "item-text": "text",
                                                      "item-value": "id",
                                                      placeholder:
                                                        "Select a Dimension",
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.getDimensionMembersLookup(
                                                          _vm.editedItem
                                                            .dimensionId
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _vm._v(
                                                                " Dimension "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "red--text",
                                                                },
                                                                [_vm._v("*")]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1739289078
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.editedItem
                                                          .dimensionId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editedItem,
                                                          "dimensionId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editedItem.dimensionId",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.isDimensionSelected &&
                                      !_vm.isDimensionMembersValid
                                        ? _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      attrs: {
                                                        color: "pink darken-1",
                                                        outlined: "",
                                                        dense: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Please select Dimension Members from the list below "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold",
                                                        },
                                                        [_vm._v("or")]
                                                      ),
                                                      _vm._v(
                                                        ' choose "Use All Members".'
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isDimensionSelected
                                        ? _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { md: "6", xs: "6" } },
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      label:
                                                        "Use All Members (dynamic)",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.editedItem
                                                          .hasAllMembers,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editedItem,
                                                          "hasAllMembers",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editedItem.hasAllMembers",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.editedItem.hasAllMembers &&
                                      _vm.isDimensionSelected
                                        ? _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c("v-switch", {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _vm.isMemberEditUnlocked
                                                            ? _c(
                                                                "span",
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "aria-hidden":
                                                                          "false",
                                                                        "aria-label":
                                                                          "Locked",
                                                                        right:
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-lock-open"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                "span",
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "aria-hidden":
                                                                          "false",
                                                                        "aria-label":
                                                                          "Locked",
                                                                        right:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-lock"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4036567182
                                                ),
                                                model: {
                                                  value:
                                                    _vm.isMemberEditUnlocked,
                                                  callback: function ($$v) {
                                                    _vm.isMemberEditUnlocked =
                                                      $$v
                                                  },
                                                  expression:
                                                    "isMemberEditUnlocked",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.editedItem.hasAllMembers &&
                                      _vm.isDimensionSelected
                                        ? _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "mb-4",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c("v-select", {
                                                    staticClass: "mb-4",
                                                    attrs: {
                                                      label:
                                                        "Dimension Members",
                                                      items:
                                                        _vm.dimensionMemberLookup,
                                                      "item-text": "text",
                                                      "item-value": "id",
                                                      placeholder:
                                                        "Select Dimension Members",
                                                      disabled:
                                                        !_vm.isMemberEditUnlocked,
                                                      outlined: "",
                                                      multiple: "",
                                                      chips: "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "prepend-item",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  attrs: {
                                                                    ripple: "",
                                                                  },
                                                                  on: {
                                                                    mousedown:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                      },
                                                                    click:
                                                                      _vm.toggleMemberListSelection,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-action",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm.hasMembers
                                                                                  ? "indigo darken-4"
                                                                                  : "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.memberListIcon
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            " Select All "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-divider", {
                                                                staticClass:
                                                                  "mt-2",
                                                              }),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      195060164
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.editedItem
                                                          .dimensionMemberIds,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editedItem,
                                                          "dimensionMemberIds",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editedItem.dimensionMemberIds",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.hasMembers &&
                                      !_vm.editedItem.hasAllMembers &&
                                      !_vm.isMemberEditUnlocked &&
                                      _vm.isDimensionSelected
                                        ? _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-simple-table", {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function () {
                                                            return [
                                                              _c("thead", [
                                                                _c("tr", [
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticClass:
                                                                        "text-left text-uppercase text-subtitle-1 primary--text font-weight-bold",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Dimension Members "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("th", {
                                                                    staticClass:
                                                                      "text-left",
                                                                  }),
                                                                ]),
                                                              ]),
                                                              _c(
                                                                "tbody",
                                                                _vm._l(
                                                                  _vm.editedItem
                                                                    .dimensionMembers,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "tr",
                                                                      {
                                                                        key: item.dimensionMemberId,
                                                                        staticClass:
                                                                          "v-chip--clickable",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.editMemberItem(
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.dimensionMemberName
                                                                              ) +
                                                                                " [" +
                                                                                _vm._s(
                                                                                  item.dimensionMemberId
                                                                                ) +
                                                                                "]"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-2",
                                                                                attrs:
                                                                                  {
                                                                                    "aria-label":
                                                                                      "Edit",
                                                                                    "aria-hidden":
                                                                                      "false",
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-pencil"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1008981814
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-uppercase",
                                              attrs: {
                                                block: "",
                                                tile: "",
                                                outlined: "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click: _vm.closeEditDialog,
                                              },
                                            },
                                            [_vm._v("Close")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _vm.isEdit
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    disabled:
                                                      !_vm.isEditItemValid,
                                                    color: "secondary",
                                                  },
                                                  on: { click: _vm.update },
                                                },
                                                [_vm._v("Update")]
                                              )
                                            : _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    disabled:
                                                      !_vm.isEditItemValid,
                                                    color: "secondary",
                                                  },
                                                  on: { click: _vm.create },
                                                },
                                                [_vm._v("Add")]
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-overlay",
                        {
                          staticClass: "align-center justify-center",
                          attrs: { value: _vm.dimensionOverlay },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", size: "64" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        fullscreen: "",
                        scrollable: "",
                        transition: "dialog-bottom-transition",
                      },
                      model: {
                        value: _vm.editMemberDialog,
                        callback: function ($$v) {
                          _vm.editMemberDialog = $$v
                        },
                        expression: "editMemberDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "mb-5 flex-grow-0",
                              attrs: { dark: "", color: "primary" },
                            },
                            [
                              _c("v-toolbar-title", [
                                _vm._v(
                                  "Edit - " +
                                    _vm._s(
                                      _vm.editedMemberItem.dimensionMemberName
                                    ) +
                                    " [" +
                                    _vm._s(
                                      _vm.editedMemberItem.dimensionMemberId
                                    ) +
                                    "]"
                                ),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", dark: "" },
                                  on: { click: _vm.closeEditMemberDialog },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "aria-label": "Close",
                                        "aria-hidden": "false",
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "productDimensionMemberEditForm",
                                  model: {
                                    value: _vm.editMemberItemIsValid,
                                    callback: function ($$v) {
                                      _vm.editMemberItemIsValid = $$v
                                    },
                                    expression: "editMemberItemIsValid",
                                  },
                                },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { md: "6", xs: "6" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "mb-4",
                                                attrs: {
                                                  label: "Display Name In Cube",
                                                  maxlength: 120,
                                                  outlined: "",
                                                  counter: "",
                                                  dense: "",
                                                  clearable: "",
                                                  hint: "Display Name for a dimension member in the Cube.",
                                                  "persistent-hint": "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedMemberItem
                                                      .displayName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedMemberItem,
                                                      "displayName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedMemberItem.displayName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { md: "6", xs: "6" } },
                                            [
                                              _c("v-textarea", {
                                                staticClass: "mb-4",
                                                attrs: {
                                                  label: "Description",
                                                  maxlength: 1000,
                                                  counter: "",
                                                  outlined: "",
                                                  "auto-grow": "",
                                                  clearable: "",
                                                  dense: "",
                                                  hint: "A brief description about the Product and Dimension Member.",
                                                  "persistent-hint": "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedMemberItem
                                                      .description,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedMemberItem,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedMemberItem.description",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-container",
                                [
                                  !_vm.editMemberItemIsValid
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    color: "pink darken-1",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "All fields must be valid and ones marked with "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "red--text",
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(
                                                    " are required. 'Save' button has been disabled until completed."
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-uppercase",
                                              attrs: {
                                                block: "",
                                                tile: "",
                                                outlined: "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click:
                                                  _vm.closeEditMemberDialog,
                                              },
                                            },
                                            [_vm._v("Close")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-uppercase",
                                              attrs: {
                                                block: "",
                                                tile: "",
                                                disabled:
                                                  !_vm.editMemberItemIsValid,
                                                color: "secondary",
                                              },
                                              on: { click: _vm.updateMember },
                                            },
                                            [_vm._v("Update")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-overlay",
                        {
                          staticClass: "align-center justify-center",
                          attrs: { value: _vm.memberOverlay },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", size: "64" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        width: "500",
                        transition: "dialog-bottom-transition",
                      },
                      model: {
                        value: _vm.deleteDialog,
                        callback: function ($$v) {
                          _vm.deleteDialog = $$v
                        },
                        expression: "deleteDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "mb-4",
                              attrs: { dark: "", color: "primary" },
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                [
                                  _c("v-icon", [_vm._v("mdi-warning")]),
                                  _vm._v("Delete"),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", dark: "" },
                                  on: { click: _vm.closeDeleteDialog },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "aria-label": "Close",
                                        "aria-hidden": "false",
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            { staticClass: "font-weight-bold text-md-body-1" },
                            [_vm._v("Are you sure you want to delete?")]
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-uppercase",
                                              attrs: {
                                                block: "",
                                                tile: "",
                                                outlined: "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click: _vm.closeDeleteDialog,
                                              },
                                            },
                                            [_vm._v("No")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-uppercase",
                                              attrs: {
                                                block: "",
                                                tile: "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click: _vm.deleteItemConfirm,
                                              },
                                            },
                                            [_vm._v("Yes")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          staticClass: "align-center justify-center",
          attrs: { value: _vm.overlay },
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }