var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data !== undefined
    ? _c(
        "div",
        { staticClass: "mainArea" },
        [
          _c("v-rect", {
            style: { zIndex: 10 },
            attrs: {
              config: {
                x: _vm.positionsOfNodes[0].x,
                y: _vm.positionsOfNodes[0].y - 40,
                width:
                  _vm.titleText.length < 40 ? 300 : _vm.titleText.length * 8,
                height: 40,
                stroke: "#fff",
                fill: _vm.getType(),
              },
            },
            on: { click: _vm.callParent },
          }),
          !_vm.isItAFloating
            ? _c("v-text", {
                style: { zIndex: 10 },
                attrs: {
                  config: {
                    text:
                      _vm.titleText +
                      `${
                        _vm.generalType === "dimension"
                          ? ` [${_vm.data[0].primaryObjectId}] `
                          : ` [${_vm.data[0].foreignObjectId}] `
                      }`,
                    x: _vm.positionsOfNodes[0].x,
                    y: _vm.positionsOfNodes[0].y - 40,
                    padding: 10,
                    fill: "#fff",
                  },
                },
                on: { click: _vm.callParent },
              })
            : _c("v-text", {
                style: { zIndex: 10 },
                attrs: {
                  config: {
                    text: _vm.titleText,
                    x: _vm.positionsOfNodes[0].x,
                    y: _vm.positionsOfNodes[0].y - 40,
                    padding: 10,
                    fill: "#fff",
                  },
                },
              }),
          _c("v-text", {
            style: { zIndex: 10 },
            attrs: {
              config: {
                text: _vm.subtitleText,
                x: _vm.positionsOfNodes[0].x,
                y: _vm.positionsOfNodes[0].y - 25,
                padding: 10,
                fill: "#fff",
                fontSize: 10,
              },
            },
          }),
          !_vm.isItAHighlight
            ? _c("v-image", {
                attrs: {
                  config: {
                    x:
                      _vm.positionsOfNodes[0].x +
                      (this.titleText.length < 40
                        ? 280
                        : this.titleText.length * 8 - 20),
                    y: _vm.positionsOfNodes[0].y - 20,
                    image: this.showFull ? this.caretUpImg : this.caretDownImg,
                    height: 10,
                    width: 10,
                  },
                },
                on: { click: _vm.setShowFull },
              })
            : _vm._e(),
          _vm._l(_vm.nonDuplicateItems, function (generalItem, index) {
            return _c("div", { key: index * 2 }, [
              !generalItem.targetObjectAttributeName
                ? _c(
                    "div",
                    [
                      _c("v-rect", {
                        style: { zIndex: 12 },
                        attrs: {
                          config: {
                            x: _vm.positionsOfNodes[index].x,
                            y:
                              _vm.getHeight(generalItem) >= 45
                                ? index === 0
                                  ? _vm.positionsOfNodes[index].y
                                  : _vm.positionsOfNodes[index].y
                                : _vm.positionsOfNodes[index].y,
                            width: _vm.positionsOfNodes[index].width,
                            height: _vm.getHeight(generalItem),
                            stroke: "#fff",
                            fill: "#F0F0F0",
                            zIndex: 99,
                          },
                        },
                      }),
                      _c("v-text", {
                        staticClass: "odd",
                        style: { zIndex: 12 },
                        attrs: {
                          config: {
                            zIndex: 99,
                            x: _vm.positionsOfNodes[index].x + 30,
                            y:
                              _vm.getHeight(generalItem) >= 45
                                ? index === 0
                                  ? _vm.getYForIndex(generalItem, index)
                                  : _vm.getYForIndex(generalItem, index) +
                                    index * 2
                                : _vm.getYForIndex(generalItem, index),
                            //y: generalType==='dimension'?generalItem.primaryAttributeName.length>46?positionsOfNodes[index].y-7:positionsOfNodes[index].y:generalItem.foreignAttributeName.length>46?positionsOfNodes[index].y-7:positionsOfNodes[index].y,
                            width: _vm.positionsOfNodes[index].width - 30,
                            height: _vm.getHeight(generalItem),
                            wrap: "word",
                            // ellipsis:true,
                            text: _vm.getText(generalItem),

                            fill: "#000",
                            padding: _vm.getHeight(generalItem) >= 40 ? 8 : 10,
                          },
                        },
                      }),
                      _vm.isThisAPrimaryKey(generalItem) && !_vm.isItAHighlight
                        ? _c("v-image", {
                            attrs: {
                              config: {
                                x:
                                  _vm.positionsOfNodes[index].x +
                                  (_vm.titleText.length < 40
                                    ? 270
                                    : _vm.titleText.length * 8 - 40),
                                y: _vm.positionsOfNodes[index].y + 10,
                                image: _vm.primaryKeyImg,
                                height: 10,
                                width: 10,
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showFull && generalItem.targetObjectAttributeName
                ? _c(
                    "div",
                    [
                      _vm.showFull
                        ? _c("v-rect", {
                            style: { zIndex: 12 },
                            attrs: {
                              config: {
                                x: _vm.positionsOfNodes[index].x,
                                y: _vm.positionsOfNodes[index].y,
                                width:
                                  index === 0
                                    ? 300
                                    : _vm.positionsOfNodes[index].width,
                                height: 30,
                                stroke: "#fff",
                                padding: 20,
                                fill: "#F0F0F0",
                              },
                            },
                          })
                        : _vm._e(),
                      _c("v-text", {
                        staticClass: "odd",
                        style: { zIndex: 12 },
                        attrs: {
                          config: {
                            x: _vm.positionsOfNodes[index].x - 5,
                            y:
                              generalItem.targetObjectAttributeName.length > 46
                                ? _vm.positionsOfNodes[index].y - 10
                                : _vm.positionsOfNodes[index].y,
                            width: _vm.positionsOfNodes[index].width,
                            height:
                              generalItem.targetObjectAttributeName.length > 46
                                ? 45
                                : 30,
                            wrap: "word",
                            // ellipsis:true,
                            text: generalItem.targetObjectAttributeName,

                            fill: "#000",
                            padding: 10,
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isItAHighlight
                ? _c(
                    "div",
                    _vm._l(
                      _vm.getAllColorBlocks(generalItem, _vm.generalType),
                      function (item, indexOfItem) {
                        return _c("div", { key: indexOfItem * 3 + "color" }, [
                          indexOfItem < 3
                            ? _c(
                                "div",
                                [
                                  _c("v-rect", {
                                    style: { zIndex: 12 },
                                    attrs: {
                                      config: {
                                        x:
                                          _vm.positionsOfNodes[index].x +
                                          (indexOfItem === 0
                                            ? 10
                                            : indexOfItem * 10 + 10),
                                        y: _vm.positionsOfNodes[index].y + 10,
                                        width: 8,
                                        height: 8,
                                        // stroke: '#fff',
                                        fill: item?.colorCode,
                                        padding: 5,
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ])
          }),
          _c("div"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }