var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "primary page-header" },
    [
      _c("top-navigation"),
      _c(
        "v-layout",
        {
          staticClass: "pa-3 header-layout",
          attrs: { column: "", "align-center": "" },
        },
        [
          _c(
            "div",
            {
              staticClass: "header-content text-center",
              on: { click: _vm.homeLink },
            },
            [
              _c(
                "div",
                { staticClass: "header-title" },
                [
                  _c("v-img", {
                    staticClass: "header-logo float-left",
                    attrs: {
                      width: "100px",
                      src: require("@/assets/unilever_logo_light.svg"),
                    },
                  }),
                  _c("div", { staticClass: "float-left" }, [
                    _c(
                      "span",
                      { staticClass: "text-center white--text header1" },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font--ul-italic float-left page-header-h2",
                          },
                          [_vm._v("D")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "font--ul-italic float-left page-header-h4",
                          },
                          [_vm._v("esign")]
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      { staticClass: "text-center white--text header2" },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font--ul-italic float-left page-header-h2",
                          },
                          [_vm._v("A")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "font--ul-italic float-left page-header-h4",
                          },
                          [_vm._v("pp")]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "header-subtitle" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-center white--text font--ul-italic page-header-h6",
                  },
                  [_vm._v("Put some design into it")]
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }