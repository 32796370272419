var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c("router-view", { attrs: { name: "pageHeader" } }),
      _c("v-main", [_c("router-view", { key: _vm.$route.path })], 1),
      _c("router-view", { attrs: { name: "pageFooter" } }),
      _c("snackbar-notification"),
      _c(
        "v-snackbar",
        {
          attrs: {
            centered: "",
            value: _vm.updateExists,
            timeout: -1,
            color: "primary",
            "multi-line": true,
            elevation: "10",
          },
        },
        [
          _c(
            "p",
            { staticClass: "pa-4" },
            [
              _vm._v(
                " An update to this App is available. Please click to update."
              ),
              _c("br"),
              _c("br"),
              _c(
                "v-btn",
                {
                  attrs: { block: "", raised: "", outlined: "" },
                  on: { click: _vm.refreshApp },
                },
                [_vm._v(" Update ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }