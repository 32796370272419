var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-fade-transition",
    { attrs: { "leave-absolute": "" } },
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            left: "",
            "max-width": "600",
            "content-class": "fade-tooltip",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mt-n2",
                          attrs: { icon: "", tabindex: "-1" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { color: "primary" } }, [
                        _vm._v(" mdi-information "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", { staticClass: "pa-2" }, [
            _c("p", [_vm._v(_vm._s(_vm.tooltipText))]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }