var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "subjectArea-index" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-2" },
                [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "text-h4 font-weight-bold text-left primary--text pb-4 float-left",
                    },
                    [_vm._v("Subject Area")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-4 float-left",
                      attrs: {
                        fab: "",
                        dark: "",
                        "x-small": "",
                        color: "primary",
                        label: "Refresh",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getItemsFiltered()
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            "aria-hidden": "false",
                            "aria-label": "Refresh",
                            dark: "",
                          },
                        },
                        [_vm._v("mdi-refresh")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-2" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-2 float-right",
                      attrs: {
                        to: "/subjectAreas/create",
                        color: "primary",
                        dark: "",
                        disabled: _vm.isBatchLocked,
                      },
                    },
                    [_vm._v("Create")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.items,
                      "item-key": "id",
                      options: _vm.options,
                      "server-items-length": _vm.totalItems,
                      loading: _vm.loading,
                      "loading-text": "Loading... Please wait",
                      "footer-props": {
                        showFirstLastPage: true,
                        itemsPerPageOptions: [20, 50, 100],
                      },
                      "fixed-header": "",
                      dense: "",
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function () {
                          return [
                            _c(
                              "v-col",
                              { attrs: { md: "12", xs: "12" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { md: "6", xs: "6" } },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Search by Subject Area",
                                            items:
                                              _vm.filteredLookupData
                                                .subjectAreas,
                                            "item-text": "text",
                                            "item-value": "id",
                                            placeholder:
                                              "Search by SubjectArea",
                                            outlined: "",
                                            dense: "",
                                            clearable: "",
                                            multiple: "",
                                          },
                                          on: { change: _vm.getItemsByOptions },
                                          model: {
                                            value:
                                              _vm.filterOptions.subjectAreaIds,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filterOptions,
                                                "subjectAreaIds",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "filterOptions.subjectAreaIds",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "item.subjectAreaName",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "v-chip--clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.subjectAreaLink(item.id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.subjectAreaName) +
                                    " [" +
                                    _vm._s(item.id) +
                                    "]"
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.loadDateText",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "v-chip--clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.subjectAreaLink(item.id)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.loadDateText))]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  "aria-label": "Edit",
                                  "aria-hidden": "false",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.subjectAreaLink(item.id)
                                  },
                                },
                              },
                              [_vm._v("mdi-pencil")]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  "aria-label": "Delete",
                                  "aria-hidden": "false",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item.id)
                                  },
                                },
                              },
                              [_vm._v("mdi-trash-can-outline")]
                            ),
                            item.diagramFlag === null
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      "aria-label": "Visualise ER Diagram",
                                      disabled: true,
                                      "aria-hidden": "false",
                                      color: "primary",
                                    },
                                  },
                                  [_vm._v("mdi-eye")]
                                )
                              : _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "", "max-width": "200" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        "aria-label":
                                                          "Visualise ER Diagram",
                                                        disabled:
                                                          !item.diagramFlag,
                                                        "aria-hidden": "false",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.visualiseStarSchemaOfSubjectArea(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-eye")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "View Logical Data Model diagram of the Subject Area"
                                      ),
                                    ]),
                                  ]
                                ),
                          ]
                        },
                      },
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _c(
                              "v-alert",
                              {
                                staticClass: "mt-4",
                                attrs: {
                                  type: "warning",
                                  dense: "",
                                  border: "left",
                                },
                              },
                              [_vm._v(" Sorry, no data to display ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "no-results",
                        fn: function () {
                          return [
                            _c(
                              "v-alert",
                              {
                                staticClass: "mt-4",
                                attrs: {
                                  type: "warning",
                                  dense: "",
                                  border: "left",
                                },
                              },
                              [_vm._v(" Sorry, no results found ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "v-overlay",
                    {
                      staticClass: "align-center justify-center",
                      attrs: { value: _vm.overlay },
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", size: "64" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        width: "500",
                        transition: "dialog-bottom-transition",
                      },
                      model: {
                        value: _vm.deleteDialog,
                        callback: function ($$v) {
                          _vm.deleteDialog = $$v
                        },
                        expression: "deleteDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "mb-4",
                              attrs: { dark: "", color: "primary" },
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                [
                                  _c("v-icon", [_vm._v("mdi-warning")]),
                                  _vm._v("Delete"),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", dark: "" },
                                  on: { click: _vm.closeDeleteDialog },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "aria-label": "Close",
                                        "aria-hidden": "false",
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            { staticClass: "font-weight-bold text-md-body-1" },
                            [_vm._v("Are you sure you want to delete?")]
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-uppercase",
                                              attrs: {
                                                block: "",
                                                tile: "",
                                                outlined: "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click: _vm.closeDeleteDialog,
                                              },
                                            },
                                            [_vm._v("No")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-uppercase",
                                              attrs: {
                                                block: "",
                                                tile: "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click: _vm.deleteItemConfirm,
                                              },
                                            },
                                            [_vm._v("Yes")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }