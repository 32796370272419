var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pt-1" },
    [
      _c(
        "v-row",
        {
          attrs: { "no-gutters": "", "justify-center": "", "align-center": "" },
        },
        [
          _c(
            "v-col",
            { attrs: { md: "7" } },
            [
              _c("v-breadcrumbs", {
                staticClass: "breadcrumbs",
                attrs: { items: _vm.breadcrumbItems },
                scopedSlots: _vm._u([
                  {
                    key: "divider",
                    fn: function () {
                      return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.showButtons
            ? _c(
                "v-col",
                { staticClass: "breadcrumbs", attrs: { cols: "3" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "align-content": "end" } },
                    [
                      _c("v-col", { staticClass: "text-end" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.zoomInZoomOutOnClick(2)
                                  },
                                },
                              },
                              [
                                _vm._v("Zoom in "),
                                _c("v-icon", [
                                  _vm._v("mdi-magnify-plus-outline"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.zoomInZoomOutOnClick(-2)
                                  },
                                },
                              },
                              [
                                _vm._v("Zoom out "),
                                _c("v-icon", [
                                  _vm._v("mdi-magnify-minus-outline"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showButtons
            ? _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "align-content": "start" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: { location: "right", "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  dark: "",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(" Download "),
                                            _c("v-icon", [
                                              _vm._v("mdi-menu-down"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3862236681
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    { key: "png" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { block: "" },
                                          on: { click: _vm.downloadAsPng },
                                        },
                                        [
                                          _vm._v("Download as Png "),
                                          _c("v-icon", [
                                            _vm._v("mdi-download"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    { key: "pdf" },
                                    [
                                      _c(
                                        "v-btn",
                                        { on: { click: _vm.downloadAsPdf } },
                                        [
                                          _vm._v("Download as pdf "),
                                          _c("v-icon", [
                                            _vm._v("mdi-download"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }