var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "nav-container" },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "primary", dark: "", flat: "" } },
        [
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "pr-0 pr-md-2",
                              attrs: { "offset-y": "", text: "" },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(" " + _vm._s(_vm.userName) + " "),
                          _c("v-icon", [_vm._v("mdi-chevron-down")]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: "/", exact: "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Home")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/object/definitions", exact: "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Object Definitions"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/object/relations", exact: "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Object Relations")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/products", exact: "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("PDS")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/subjectareas", exact: "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Subject Area")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isUserAdmin || _vm.isDataAdmin
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/admin", exact: "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Admin")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    { on: { click: this.logOut } },
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Log out")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }