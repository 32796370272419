var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "container", staticClass: "main-stage", staticStyle: {} },
    [
      _c("TitleComponentForER", {
        attrs: {
          showButtons: _vm.showTables,
          breadcrumbItems: _vm.breadcrumbItems,
        },
        on: {
          "zoom-in-zoom-out": _vm.zoomInZoomOutOnClick,
          "download-image-as-pdf": _vm.downloadImageAsPdf,
          "download-as-png": _vm.downloadAsPng,
        },
      }),
      !_vm.showTables
        ? _c("v-container", [_c("SkeletonLoaderTable")], 1)
        : _vm._e(),
      _c("BaseHeader", {
        attrs: {
          showArrows: _vm.showTables,
          subjectAreaName: _vm.subjectAreaName,
          dataLength: _vm.data.length,
          date: _vm.getDate(),
          floatingTablesDataLength: _vm.floatingTablesDataLength,
        },
        on: {
          "show-hide-floating-inner": _vm.showHideFloatingTablesInner,
          "get-title-ref": _vm.getTitleRef,
        },
      }),
      _vm.showTables
        ? _c(
            "div",
            [
              _vm.data.length <= 0 && _vm.floatingTablesDataLength <= 0
                ? _c("v-container", [_c("NoData")], 1)
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("div", { ref: "containertwo" }, [
        _c("div", { style: { display: "flex", flexDirection: "row" } }, [
          _vm.showFloating
            ? _c(
                "div",
                [
                  _vm.showFloating
                    ? _c(
                        "v-stage",
                        {
                          ref: "stageRefForFloating",
                          style: { height: "100vh", overflow: "auto" },
                          attrs: { config: _vm.configForStageOfFloating },
                        },
                        [
                          _c(
                            "v-layer",
                            [
                              _vm.floatingTablesData.length > 0
                                ? _c("v-group", [
                                    _c(
                                      "div",
                                      _vm._l(
                                        _vm.floatingTablesData,
                                        function (item, floatingIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key:
                                                "floatingTablesUniqueKey" +
                                                floatingIndex,
                                            },
                                            [
                                              _c("ERDTable", {
                                                key: "fact" + floatingIndex * 1,
                                                attrs: {
                                                  isItAFloating: true,
                                                  "title-text":
                                                    item.targetObjectName +
                                                    " [" +
                                                    item.targetObjectId +
                                                    "] ",
                                                  "subtitle-text":
                                                    item.platformDetails,
                                                  data: item,
                                                  position: _vm.getPosition({
                                                    type: item.type,
                                                    item: item.targetObjectName,
                                                    y: 40,
                                                    isItFloating: true,
                                                    isItAHighlight: false,
                                                  }),
                                                  "general-type":
                                                    item.targetObjectTypeName,
                                                  type: item.targetObjectTypeName,
                                                },
                                                on: {
                                                  "show-fact-relations":
                                                    _vm.showFactRelations,
                                                  "get-toa-data":
                                                    _vm.getToaData,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.isHighlightVisible
            ? _c(
                "div",
                { style: _vm.highlightStyle },
                [
                  _c(
                    "v-stage",
                    {
                      ref: "stageRefForFloating",
                      attrs: { config: _vm.configForStageOfHighlight },
                    },
                    [
                      _c(
                        "v-layer",
                        [
                          _vm.isHighlightVisible
                            ? _c("v-group", [
                                _vm.isHighlightVisible
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-text", {
                                          attrs: {
                                            config:
                                              _vm.relationshipPreviewConfig,
                                          },
                                        }),
                                        _c("ERDTable", {
                                          key: "fact" + 1,
                                          attrs: {
                                            isItAFloating: false,
                                            isItAHighlight: true,
                                            "title-text":
                                              _vm.highlightItems
                                                .foreignObjectName,
                                            data: _vm.getFactItemsForHighlight({
                                              highlightItems:
                                                _vm.highlightItems,
                                              type: "fact",
                                            }),
                                            position: _vm.getPosition({
                                              type: "floating",
                                              item:
                                                _vm.highlightItems
                                                  .foreignObjectName +
                                                _vm.highlightItems
                                                  .foreignObjectID +
                                                _vm.highlightItems
                                                  .primaryObjectID,
                                              y: 40,
                                              isItFloating: false,
                                              isItAHighlight: true,
                                            }),
                                            "general-type": "fact",
                                            type: _vm.factTables[
                                              _vm.highlightItems
                                                .foreignObjectName
                                            ]
                                              ? _vm.factTables[
                                                  _vm.highlightItems
                                                    .foreignObjectName
                                                ][0]?.foreignObjectType
                                              : _vm.dimensionTables[
                                                  _vm.highlightItems
                                                    .foreignObjectName
                                                ][0]?.foreignObjectType,
                                          },
                                        }),
                                        _c("ERDTable", {
                                          key: "dim" + 2,
                                          attrs: {
                                            isItAFloating: false,
                                            isItAHighlight: true,
                                            "title-text":
                                              _vm.highlightItems
                                                .primaryObjectName,
                                            "general-type": "dimension",
                                            data: _vm.getFactItemsForHighlight({
                                              highlightItems:
                                                _vm.highlightItems,
                                              type: "dimension",
                                            }),
                                            position: _vm.getPosition({
                                              type: "floating",
                                              associatedfactTable:
                                                _vm.highlightItems
                                                  .primaryObjectName,
                                              item:
                                                _vm.highlightItems
                                                  .primaryObjectName +
                                                _vm.highlightItems
                                                  .foreignObjectID +
                                                _vm.highlightItems
                                                  .primaryObjectID,
                                              y: 40,
                                              isItFloating: false,
                                              isItAHighlight: true,
                                            }),
                                            type:
                                              _vm.dimensionTables[
                                                _vm.highlightItems
                                                  .primaryObjectName
                                              ] &&
                                              _vm.dimensionTables[
                                                _vm.highlightItems
                                                  .primaryObjectName
                                              ][0].primaryObjectType,
                                          },
                                        }),
                                        _vm.factHashesForFloating.length > 1
                                          ? _c(
                                              "div",
                                              [
                                                _c("v-arrow", {
                                                  attrs: {
                                                    config: {
                                                      points:
                                                        _vm.getPointsForHighlight(
                                                          _vm.highlightItems
                                                        ),
                                                      fill: "blue",
                                                      stroke: "blue",
                                                      strokeWidth: 4,
                                                      pointerWidth: 12,
                                                      pointerLength: 12,
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "v-stage",
                { ref: "stageRef", attrs: { config: _vm.configKonva } },
                [
                  _vm.showArrows
                    ? _c(
                        "v-layer",
                        [
                          _c("v-group", [
                            _c(
                              "div",
                              [
                                _vm.arrowsOfHighlightToAppear
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.highlightArrowsItems,
                                        function (item, indexForArrows) {
                                          return _c(
                                            "div",
                                            { key: indexForArrows },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  key:
                                                    "arrowdiv" + indexForArrows,
                                                },
                                                [
                                                  _c("v-arrow", {
                                                    key:
                                                      "arrowKey" +
                                                      indexForArrows,
                                                    ref:
                                                      "arrow" + indexForArrows,
                                                    refInFor: true,
                                                    attrs: { config: item },
                                                    on: {
                                                      click: ($event) =>
                                                        _vm.showHighlightedEnter(
                                                          $event,
                                                          item,
                                                          "#0533cc",
                                                          "#0533cc",
                                                          item.colorCode
                                                        ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._l(
                                  _vm.data,
                                  function (item, indexForMetadata) {
                                    return _c(
                                      "div",
                                      { key: indexForMetadata },
                                      [
                                        _c(
                                          "div",
                                          {
                                            key: "arrowdiv" + indexForMetadata,
                                          },
                                          [
                                            _vm.showArrows
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("v-circle", {
                                                      attrs: {
                                                        config:
                                                          _vm.getUniqueCircle(
                                                            item.primaryObjectName +
                                                              item.foreignObjectName +
                                                              item.foreignObjectId +
                                                              item.primaryObjectId
                                                          ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c("v-arrow", {
                                              key:
                                                "arrowKey" +
                                                item.primaryAttributeName +
                                                item.primaryObjectId +
                                                item.foreignObjectName,
                                              ref:
                                                "arrow" +
                                                item.primaryAttributeName +
                                                item.primaryObjectId +
                                                item.foreignObjectName,
                                              refInFor: true,
                                              attrs: {
                                                config:
                                                  _vm.uniqueHashMapForArrows[
                                                    item.primaryObjectName +
                                                      item.foreignObjectName +
                                                      item.foreignObjectId +
                                                      item.primaryObjectId
                                                  ],
                                              },
                                              on: {
                                                click: ($event) =>
                                                  _vm.showHighlightedEnter(
                                                    $event,
                                                    item,
                                                    "#0533cc",
                                                    "#0533cc",
                                                    item.colorCode
                                                  ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showTables
                    ? _c(
                        "v-layer",
                        [
                          _c("v-group", [
                            _c(
                              "div",
                              {
                                style: {
                                  overflowY: "scroll",
                                  overflowX: "scroll",
                                },
                              },
                              [
                                _vm._l(
                                  Object.keys(
                                    _vm.dimensionToFactAssociatedHash
                                  ),
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: "fact" + index },
                                      [
                                        _vm.factTables[item] &&
                                        _vm.factTables[item][0]
                                          ? _c(
                                              "div",
                                              [
                                                _c("ERDTable", {
                                                  key: "fact" + index * 1,
                                                  attrs: {
                                                    isItAFloating: false,
                                                    "title-text": item,
                                                    "subtitle-text":
                                                      _vm.factTables[item][0]
                                                        ?.foreignPlatformDetails,
                                                    data: _vm.factTables[item],
                                                    position: _vm.getPosition({
                                                      type: "fact",
                                                      item: item,
                                                      y: 40,
                                                      key: item,
                                                      isItFloating: false,
                                                      isItAHighlight: false,
                                                    }),
                                                    "general-type": "fact",
                                                    type:
                                                      _vm.factTables[item] &&
                                                      _vm.factTables[item][0]
                                                        ?.foreignObjectType,
                                                  },
                                                  on: {
                                                    "show-fact-relations":
                                                      _vm.showFactRelations,
                                                    "get-toa-data":
                                                      _vm.getToaData,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          _vm.dimensionToFactAssociatedHash[
                                            item
                                          ],
                                          function (innerItem, index) {
                                            return _c(
                                              "div",
                                              { key: "dimension" + index },
                                              [
                                                _vm.dimensionTables[
                                                  innerItem
                                                ] &&
                                                _vm.dimensionTables[
                                                  innerItem
                                                ][0]
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("ERDTable", {
                                                          key:
                                                            "dim" + index * 1,
                                                          attrs: {
                                                            isItAFloating: false,
                                                            "title-text":
                                                              innerItem,
                                                            "subtitle-text":
                                                              _vm
                                                                .dimensionTables[
                                                                innerItem
                                                              ][0]
                                                                ?.primaryPlatformDetails,
                                                            "general-type":
                                                              "dimension",
                                                            data: _vm
                                                              .dimensionTables[
                                                              innerItem
                                                            ],
                                                            position:
                                                              _vm.getPosition({
                                                                type: "dimension",
                                                                associatedfactTable:
                                                                  item,
                                                                item: innerItem,
                                                                y: 40,
                                                                key: innerItem,
                                                                isItFloating: false,
                                                                isItAHighlight: false,
                                                              }),
                                                            type:
                                                              _vm
                                                                .dimensionTables[
                                                                innerItem
                                                              ] &&
                                                              _vm
                                                                .dimensionTables[
                                                                innerItem
                                                              ][0]
                                                                ?.primaryObjectType,
                                                          },
                                                          on: {
                                                            "show-fact-relations":
                                                              _vm.showFactRelations,
                                                            "get-toa-data":
                                                              _vm.getToaData,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  }
                                ),
                                _vm.showHighlight
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.positionOfHighlightElements,
                                        function (
                                          positionItem,
                                          positionItemIndex
                                        ) {
                                          return _c(
                                            "div",
                                            { key: positionItemIndex },
                                            [
                                              _c("v-rect", {
                                                key:
                                                  positionItem?.xPositionOfSource +
                                                  positionItemIndex,
                                                attrs: {
                                                  config: {
                                                    x: positionItem?.xPositionOfSource,
                                                    y: positionItem?.yPositionOfSource,
                                                    width: positionItem?.width,
                                                    height: 30,
                                                    stroke: "black",
                                                    padding: 3,
                                                    zIndex: 90,
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        [
          _c("DiagramCommentsBox", {
            attrs: { productId: _vm.stageId, url: _vm.url },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }