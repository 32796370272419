var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "v-expansion-panels",
        [
          _c(
            "v-expansion-panel",
            [
              _c(
                "v-expansion-panel-header",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function () {
                        return [
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v(" mdi-information "),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_vm._v(" " + _vm._s(_vm.header) + " ")]
              ),
              _c("v-expansion-panel-content", [_vm._v(_vm._s(_vm.text))]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }