var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "not-authenticated" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { md: "12", xs: "12" } }, [
                _c(
                  "h2",
                  { staticClass: "text-center", attrs: { color: "primary" } },
                  [_vm._v("No Access")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", attrs: { color: "primary" } },
                  [
                    _vm._v(
                      "You must be authenticated and be added as a User with an appropriate role, to access this Web Application."
                    ),
                  ]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", attrs: { color: "primary" } },
                  [
                    _vm._v(
                      "Please contact a User Admin or try logging out and logging in again."
                    ),
                  ]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", attrs: { color: "primary" } },
                  [
                    _vm._v(
                      "You can logout and login again using the top right menu to logout and then the home link to attempt a login again."
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }