var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-alert",
            { attrs: { color: "pink darken-1", outlined: "", dense: "" } },
            [
              _c("p", [_vm._v("No Logical Data Model diagram available")]),
              _c("p", [_vm._v(" Please ensure that: ")]),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "Measure groups and dimensions are added for this product"
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    "Object relations are created between the measure groups (facts) and dimensions"
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }