var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "products-form" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-form",
            {
              ref: "productsForm",
              model: {
                value: _vm.editItemIsValid,
                callback: function ($$v) {
                  _vm.editItemIsValid = $$v
                },
                expression: "editItemIsValid",
              },
            },
            [
              !_vm.editItemIsValid
                ? _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                color: "pink darken-1",
                                outlined: "",
                                dense: "",
                              },
                            },
                            [
                              _vm._v(
                                "All data provided must be valid. Any fields marked with "
                              ),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" are required."),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Product Name",
                          maxlength: 100,
                          rules: [(v) => !!v || "Product Name is required"],
                          required: "",
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          hint: "The name of the Product should be unique for the given Platform.",
                          "persistent-hint": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(" Product Name "),
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "productName", $$v)
                          },
                          expression: "editedItem.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Display Name",
                          maxlength: 100,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          hint: "This is often the same as the ProductName but should be in a readable format.",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.displayName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "displayName", $$v)
                          },
                          expression: "editedItem.displayName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-textarea", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Description",
                          maxlength: 1000,
                          counter: "",
                          outlined: "",
                          "auto-grow": "",
                          clearable: "",
                          dense: "",
                          hint: "A brief description of the product which should be in readable English.",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "description", $$v)
                          },
                          expression: "editedItem.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Platform",
                          items: _vm.lookupData.platforms,
                          "item-text": "text",
                          "item-value": "id",
                          rules: [(v) => !!v || "Select a Platform"],
                          placeholder: "Select a Platform",
                          required: "",
                          outlined: "",
                          dense: "",
                          hint: "Select the source system providing the data. If not available in drop-down, request addition from Template Owner.",
                          "persistent-hint": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(" Platform "),
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.platformId,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "platformId", $$v)
                          },
                          expression: "editedItem.platformId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Diagram Type",
                          items: _vm.lookupData.diagramTypes,
                          "item-text": "text",
                          "item-value": "id",
                          rules: [(v) => !!v || "Select a Diagram Type"],
                          placeholder: "Select a Diagram Type",
                          required: "",
                          outlined: "",
                          dense: "",
                          hint: "Select the source system providing the data. If not available in drop-down, request addition from Template Owner.",
                          "persistent-hint": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(" Diagram Type "),
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.diagramTypeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "diagramTypeId", $$v)
                          },
                          expression: "editedItem.diagramTypeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Business Owner",
                          items: _vm.lookupData.dataOwners,
                          "item-text": "text",
                          "item-value": "id",
                          rules: [(v) => !!v || "Select a Business Owner"],
                          placeholder: "Select a Business Owner",
                          required: "",
                          outlined: "",
                          dense: "",
                          hint: "Who is the business owner of the data, select value from dropdown. If unknown then select 'UNDEFINED'.",
                          "persistent-hint": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(" Business Owner "),
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.businessOwnerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "businessOwnerId", $$v)
                          },
                          expression: "editedItem.businessOwnerId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Lead SME",
                          items: _vm.lookupData.dataOwners,
                          "item-text": "text",
                          "item-value": "id",
                          rules: [(v) => !!v || "Select a Lead SME"],
                          placeholder: "Select a Lead SME",
                          required: "",
                          outlined: "",
                          dense: "",
                          hint: "Who is the lead SME of the data, select from dropdown.",
                          "persistent-hint": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(" Lead SME "),
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.editedItem.leadSMEId,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "leadSMEId", $$v)
                          },
                          expression: "editedItem.leadSMEId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Refresh Frequency",
                          maxlength: 50,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          hint: "Refresh frequency of the product.",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.refreshFrequency,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "refreshFrequency", $$v)
                          },
                          expression: "editedItem.refreshFrequency",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Solution Overview",
                          maxlength: 250,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          hint: "Brief overview of the proposed solution",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.solutionOverview,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "solutionOverview", $$v)
                          },
                          expression: "editedItem.solutionOverview",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Business Challenge",
                          maxlength: 250,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          hint: "Any business challenges should be defined here",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.businessChallenge,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "businessChallenge", $$v)
                          },
                          expression: "editedItem.businessChallenge",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Business Value",
                          maxlength: 250,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          hint: "How is business value added, if none input none.",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.businessValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "businessValue", $$v)
                          },
                          expression: "editedItem.businessValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Row Limitations",
                          maxlength: 250,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          hint: "Any row limitations should be defined here.",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.rowLimitations,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "rowLimitations", $$v)
                          },
                          expression: "editedItem.rowLimitations",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Geography in Scope",
                          maxlength: 250,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          hint: "Geography in Scope i.e. Regional, Global",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.geographyInScope,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "geographyInScope", $$v)
                          },
                          expression: "editedItem.geographyInScope",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Expected Number of Users",
                          items: _vm.numberList,
                          placeholder: "Select the number of users",
                          outlined: "",
                          dense: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.editedItem.expNumOfUsers,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "expNumOfUsers", $$v)
                          },
                          expression: "editedItem.expNumOfUsers",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Expected Number of Concurrent Users",
                          items: _vm.numberList,
                          placeholder: "Select the number of concurrent users",
                          outlined: "",
                          dense: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.editedItem.expNumOfConcUsers,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "expNumOfConcUsers", $$v)
                          },
                          expression: "editedItem.expNumOfConcUsers",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Data Classification",
                          hint: "Is the data Confidential or Internal data. If unknown mark as UNDEFINED.",
                          items: _vm.lookupData.dataClassifications,
                          placeholder: "Select the data classification",
                          outlined: "",
                          dense: "",
                          clearable: "",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.dataClassification,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "dataClassification", $$v)
                          },
                          expression: "editedItem.dataClassification",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Expected Response",
                          maxlength: 250,
                          outlined: "",
                          counter: "",
                          dense: "",
                          clearable: "",
                          hint: "Expected Response time from the server, define in seconds",
                          placeholder: "Select the expected response time",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.expectedResponse,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "expectedResponse", $$v)
                          },
                          expression: "editedItem.expectedResponse",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mb-4",
                                          attrs: {
                                            label: "Expected Delivery Date",
                                            "prepend-icon": "mdi-calendar",
                                            readonly: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value:
                                              _vm.editedItem
                                                .deliveryDateExpectedAsText,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "deliveryDateExpectedAsText",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editedItem.deliveryDateExpectedAsText",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.deliveryDateExpectedMenu,
                            callback: function ($$v) {
                              _vm.deliveryDateExpectedMenu = $$v
                            },
                            expression: "deliveryDateExpectedMenu",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            on: {
                              input: function ($event) {
                                _vm.deliveryDateExpectedMenu = false
                              },
                            },
                            model: {
                              value: _vm.editedItem.deliveryDateExpectedAsText,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editedItem,
                                  "deliveryDateExpectedAsText",
                                  $$v
                                )
                              },
                              expression:
                                "editedItem.deliveryDateExpectedAsText",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mb-4",
                                          attrs: {
                                            label: "Committed Delivery Date",
                                            "prepend-icon": "mdi-calendar",
                                            readonly: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value:
                                              _vm.editedItem
                                                .deliveryDateCommittedAsText,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "deliveryDateCommittedAsText",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editedItem.deliveryDateCommittedAsText",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.deliveryDateCommittedMenu,
                            callback: function ($$v) {
                              _vm.deliveryDateCommittedMenu = $$v
                            },
                            expression: "deliveryDateCommittedMenu",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            on: {
                              input: function ($event) {
                                _vm.deliveryDateCommittedMenu = false
                              },
                            },
                            model: {
                              value: _vm.editedItem.deliveryDateCommittedAsText,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editedItem,
                                  "deliveryDateCommittedAsText",
                                  $$v
                                )
                              },
                              expression:
                                "editedItem.deliveryDateCommittedAsText",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-textarea", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Diagram Notes",
                          maxlength: 4000,
                          counter: "",
                          outlined: "",
                          "auto-grow": "",
                          clearable: "",
                          dense: "",
                          hint: "Notes related to STAR SCHEMA diagram for a Product.",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.diagramNotes,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "diagramNotes", $$v)
                          },
                          expression: "editedItem.diagramNotes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-textarea", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "SME Notes Product",
                          maxlength: 4000,
                          counter: "",
                          outlined: "",
                          "auto-grow": "",
                          clearable: "",
                          dense: "",
                          hint: "Notes from SME for a Product.",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editedItem.smeNotesProduct,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "smeNotesProduct", $$v)
                          },
                          expression: "editedItem.smeNotesProduct",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isBatchLocked
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            color: "pink darken-1",
                            outlined: "",
                            dense: "",
                          },
                        },
                        [
                          _vm._v(
                            " There is a scheduled job in progress that must be completed. Please wait and try refreshing later. Saving is currently disabled."
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isNameExisting
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-6",
                          attrs: {
                            border: "bottom",
                            color: "red darken-2",
                            dark: "",
                          },
                        },
                        [
                          _vm._v(
                            "The Product Name is already being used by another Product in the same Platform, please try a different Product Name or change the Platform."
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.editItemIsValid
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            color: "pink darken-1",
                            outlined: "",
                            dense: "",
                          },
                        },
                        [
                          _vm._v(
                            "All data provided must be valid. Any fields marked with "
                          ),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(" are required. "),
                          _vm.isEditState
                            ? _c("span", [_vm._v("'Save'")])
                            : _vm._e(),
                          _vm.isCreateState
                            ? _c("span", [_vm._v("'Create'")])
                            : _vm._e(),
                          _vm._v(" button has been disabled until completed."),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.isCreateState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            outlined: "",
                            href: "/products",
                            color: "secondary",
                          },
                        },
                        [_vm._v("Back")]
                      )
                    : _vm._e(),
                  _vm.isEditState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            outlined: "",
                            href: "/products",
                            color: "secondary",
                          },
                        },
                        [_vm._v("Cancel")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _vm.isCreateState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            disabled: !_vm.editItemIsValid || _vm.isBatchLocked,
                            color: "secondary",
                          },
                          on: { click: _vm.create },
                        },
                        [_vm._v("Create")]
                      )
                    : _vm._e(),
                  _vm.isEditState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            block: "",
                            tile: "",
                            disabled: !_vm.editItemIsValid || _vm.isBatchLocked,
                            color: "secondary",
                          },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Save")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          staticClass: "align-center justify-center",
          attrs: { value: _vm.overlay },
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }