var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "card" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }