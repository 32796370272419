var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-2" },
            [
              _c(
                "h1",
                {
                  staticClass:
                    "text-h4 font-weight-bold text-left primary--text pb-4 float-left",
                },
                [_vm._v("Source Object Attributes")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-4 float-left",
                  attrs: {
                    fab: "",
                    dark: "",
                    "x-small": "",
                    color: "primary",
                    label: "Refresh",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getItemsFiltered()
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        "aria-hidden": "false",
                        "aria-label": "Refresh",
                        dark: "",
                      },
                    },
                    [_vm._v("mdi-refresh")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-2" },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  "item-key": "sourceObjectAttributeId",
                  options: _vm.options,
                  "server-items-length": _vm.totalItems,
                  loading: _vm.loading,
                  "loading-text": "Loading... Please wait",
                  "footer-props": {
                    showFirstLastPage: true,
                    itemsPerPageOptions: [20, 50, 100],
                  },
                  "fixed-header": "",
                  dense: "",
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-col",
                          { attrs: { md: "12", xs: "12" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { md: "6", xs: "6" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label:
                                          "Search by Source Object Attribute",
                                        items:
                                          _vm.filteredLookupData
                                            .sourceObjectAttributes,
                                        "item-text": "text",
                                        "item-value": "id",
                                        placeholder:
                                          "Search by Source Object Attribute",
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        multiple: "",
                                      },
                                      on: { change: _vm.getItemsByOptions },
                                      model: {
                                        value:
                                          _vm.filterOptions
                                            .sourceObjectAttributeIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterOptions,
                                            "sourceObjectAttributeIds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filterOptions.sourceObjectAttributeIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { md: "6", xs: "6" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label: "Search by Source Object",
                                        items:
                                          _vm.filteredLookupData.sourceObjects,
                                        "item-text": "text",
                                        "item-value": "id",
                                        placeholder: "Search by Source Object",
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        multiple: "",
                                      },
                                      on: { change: _vm.getItemsByOptions },
                                      model: {
                                        value:
                                          _vm.filterOptions.sourceObjectIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterOptions,
                                            "sourceObjectIds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filterOptions.sourceObjectIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.sourceObjectAttributeName",
                    fn: function ({ item }) {
                      return [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "v-chip--clickable",
                              on: {
                                click: function ($event) {
                                  return _vm.editItem(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(item.sourceObjectAttributeName) +
                                  " [" +
                                  _vm._s(item.id) +
                                  "]"
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.loadDateText",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItem(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.loadDateText))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.sourceObjectName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItem(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(item.sourceObjectName) +
                                " [" +
                                _vm._s(item.sourceObjectId) +
                                "] " +
                                _vm._s(item.platformName) +
                                " [" +
                                _vm._s(item.platformId) +
                                "]"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "", "max-width": "400" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                "aria-label": "Edit",
                                                "aria-hidden": "false",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editItem(item)
                                                },
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-pencil")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Click to Edit/View this item. You must be the data owner or data set owner to Save any changes"
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "no-data",
                    fn: function () {
                      return [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              type: "warning",
                              dense: "",
                              border: "left",
                            },
                          },
                          [_vm._v(" Sorry, no data to display ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "no-results",
                    fn: function () {
                      return [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              type: "warning",
                              dense: "",
                              border: "left",
                            },
                          },
                          [_vm._v(" Sorry, no results found ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "v-overlay",
                {
                  staticClass: "align-center justify-center",
                  attrs: { value: _vm.overlay },
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "64" },
                  }),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: "",
                    scrollable: "",
                    transition: "dialog-bottom-transition",
                  },
                  model: {
                    value: _vm.editDialog,
                    callback: function ($$v) {
                      _vm.editDialog = $$v
                    },
                    expression: "editDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-5 flex-grow-0",
                          attrs: { dark: "", color: "primary" },
                        },
                        [
                          _c("v-toolbar-title", [
                            _vm._v(
                              "Edit - " +
                                _vm._s(
                                  _vm.editedItem.sourceObjectAttributeName
                                ) +
                                " [" +
                                _vm._s(_vm.editedItem.sourceObjectAttributeId) +
                                "]"
                            ),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeEditDialog },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "aria-label": "Close",
                                    "aria-hidden": "false",
                                  },
                                },
                                [_vm._v("mdi-close")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "py-3" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "sourceObjectAttributeEditForm",
                              model: {
                                value: _vm.editItemIsValid,
                                callback: function ($$v) {
                                  _vm.editItemIsValid = $$v
                                },
                                expression: "editItemIsValid",
                              },
                            },
                            [
                              _c(
                                "v-container",
                                { attrs: { fluid: "" } },
                                [
                                  !_vm.editItemIsValid
                                    ? _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    color: "pink darken-1",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "All data provided must be valid. Any fields marked with "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "red--text",
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" are required."),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData.sourceObjects,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Select a Source Object",
                                              ],
                                              placeholder:
                                                "Select a Source Object",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Source Object Name "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem.sourceObjectId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "sourceObjectId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.sourceObjectId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 150,
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Source Object Attribute Name is required",
                                              ],
                                              required: "",
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "MANDATORY FIELD - Attribute name - must match attribute name in source, UDL and data catalogue/DMR.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Source Object Attribute Name "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .sourceObjectAttributeName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "sourceObjectAttributeName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.sourceObjectAttributeName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Description",
                                              maxlength: 1000,
                                              required: "",
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "English meaningful description of source attribute. This should qualify the attribute for e.g. a data dictionary",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(" Description "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: {
                                                rules: [
                                                  (v) =>
                                                    v != null ||
                                                    "Select an answer",
                                                ],
                                                dense: "",
                                                "persistent-hint": "",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(" MandatoryYN "),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem.mandatoryYN,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "mandatoryYN",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.mandatoryYN",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData
                                                  .personalIdentifiableInformations,
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select Personal Identifiable Information",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: " Is the data PII?",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Personal Identifiable Information (SME) "
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .personalIdentifiableInformationId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "personalIdentifiableInformationId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.personalIdentifiableInformationId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items: _vm.lookupData.dataTypes,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v || "Select a Data Type",
                                              ],
                                              placeholder: "Select a Data Type",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              hint: "Data-type of attribute in source system. Request new data-types via Template Owners.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(" Data Type "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.dataTypeId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataTypeId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataTypeId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: {
                                                rules: [
                                                  (v) =>
                                                    v != null ||
                                                    "Select an answer",
                                                ],
                                                dense: "",
                                                hint: "Flag if this field is a foreign key to another source object.",
                                                "persistent-hint": "",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Is Foreign Key? "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem.foreignKey,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "foreignKey",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.foreignKey",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: {
                                                rules: [
                                                  (v) =>
                                                    v != null ||
                                                    "Select an answer",
                                                ],
                                                dense: "",
                                                hint: "Set to YES for every attribute forming the Primary Key of the object.",
                                                "persistent-hint": "",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Is Primary Key? "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem.primaryKey,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "primaryKey",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.primaryKey",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: {
                                                rules: [
                                                  (v) =>
                                                    v != null ||
                                                    "Select an answer",
                                                ],
                                                dense: "",
                                                hint: "For documentation purposes. Defines whether there can be gaps in the source data. If Nullable = True, gaps are fine. If Nullable = False, then no gaps should be present.",
                                                "persistent-hint": "",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(" Is Nullable? "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value: _vm.editedItem.nullable,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "nullable",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.nullable",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items: _vm.requestTypes,
                                              label: "Request Type",
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select an Request Type",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "append-outer",
                                                fn: function () {
                                                  return undefined
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.requestType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "requestType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.requestType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Request Type Reason",
                                              maxlength: 1000,
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .requestTypeReason,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "requestTypeReason",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.requestTypeReason",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Source Name Alternative",
                                              maxlength: 50,
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .sourceNameAlternative,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "sourceNameAlternative",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.sourceNameAlternative",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Data Quality NULL Check? "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.editedItem.nullCheck =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value: _vm.editedItem.nullCheck,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "nullCheck",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.nullCheck",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Data Quality Duplicate Check? "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.editedItem.duplicateCheck =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem.duplicateCheck,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "duplicateCheck",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.duplicateCheck",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Data Quality Data Type Check? "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.editedItem.dataTypeCheck =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem.dataTypeCheck,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "dataTypeCheck",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.dataTypeCheck",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Data Quality Data Format",
                                              maxlength: 100,
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.editedItem.dataFormat,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataFormat",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataFormat",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-container",
                            [
                              !_vm.editItemIsValid
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                color: "pink darken-1",
                                                outlined: "",
                                                dense: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "All data provided must be valid. Any fields marked with "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v(
                                                " are required. 'Save' button has been disabled until completed."
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isBatchLocked
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                color: "pink darken-1",
                                                outlined: "",
                                                dense: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " There is a scheduled job in progress that must be completed. Please wait and try refreshing later. Saving is currently disabled."
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.editedItem.isEditable
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                color: "pink darken-1",
                                                outlined: "",
                                                dense: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "You must be the data owner or data set owner to 'Save' this item"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            block: "",
                                            tile: "",
                                            outlined: "",
                                            color: "secondary",
                                          },
                                          on: { click: _vm.closeEditDialog },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            block: "",
                                            tile: "",
                                            disabled:
                                              !_vm.editItemIsValid ||
                                              _vm.isBatchLocked ||
                                              !_vm.editedItem.isEditable,
                                            color: "secondary",
                                          },
                                          on: { click: _vm.save },
                                        },
                                        [_vm._v("Save")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }