var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-footer",
    { staticClass: "light-grey mt-4" },
    [
      _c("v-layout", {
        staticClass: "pt-md-5 pb-md-5",
        attrs: { column: "", "align-center": "" },
      }),
      _c("v-img", {
        staticClass: "float-right mr-md-5",
        staticStyle: { position: "absolute", right: "0" },
        attrs: {
          position: "right",
          height: "50",
          contain: "",
          src: require("@/assets/unilever_logo.svg"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }