var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "admin" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-1", attrs: { justify: "space-around" } },
            [
              _c(
                "v-col",
                { attrs: { md: "5", xs: "5" } },
                [
                  _vm.isUserAdmin
                    ? _c(
                        "v-card",
                        {
                          attrs: {
                            to: "/admin/users",
                            elevation: "5",
                            outlined: "",
                            shaped: "",
                          },
                        },
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "mb-0 flex-grow-0",
                              attrs: { dark: "", color: "primary" },
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticClass: "text-h6" },
                                [_vm._v("Users")]
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-2", attrs: { large: "" } },
                                [_vm._v("mdi-account")]
                              ),
                            ],
                            1
                          ),
                          _c("v-card-subtitle", { staticClass: "text-h6" }, [
                            _vm._v("Create or Update existing Users"),
                          ]),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    color: "primary accent-4",
                                  },
                                },
                                [_vm._v("GO")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "5", xs: "5" } },
                [
                  _vm.isDataAdmin
                    ? _c(
                        "v-card",
                        {
                          attrs: {
                            to: "/admin/platforms",
                            elevation: "5",
                            outlined: "",
                            shaped: "",
                          },
                        },
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "mb-0 flex-grow-0",
                              attrs: { dark: "", color: "primary" },
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticClass: "text-h6" },
                                [_vm._v("Platforms")]
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-2", attrs: { large: "" } },
                                [_vm._v("mdi-cog")]
                              ),
                            ],
                            1
                          ),
                          _c("v-card-subtitle", { staticClass: "text-h6" }, [
                            _vm._v("Create or Update existing Platforms."),
                          ]),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    color: "primary accent-4",
                                  },
                                },
                                [_vm._v("GO")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }