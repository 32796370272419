var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-snackbar",
    {
      attrs: {
        color: _vm.snackBarColour,
        "multi-line": true,
        timeout: _vm.snackBarTimeout,
        bottom: true,
        left: true,
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.hideNotification()
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showNotification,
        callback: function ($$v) {
          _vm.showNotification = $$v
        },
        expression: "showNotification",
      },
    },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "", "pr-4": "" } },
        [
          _c(
            "v-icon",
            { staticClass: "pr-3", attrs: { dark: "", large: "" } },
            [_vm._v(_vm._s(_vm.snackBarIcon))]
          ),
          _c("v-layout", { attrs: { column: "" } }, [
            _c("div", [_c("strong", [_vm._v(_vm._s(_vm.snackBarTitle))])]),
            _c("p", [_vm._v(_vm._s(_vm.snackBarMessage))]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }