var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-1", attrs: { justify: "space-around" } },
            [
              _c(
                "v-col",
                { attrs: { md: "5", xs: "5" } },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        to: "/object/definitions",
                        elevation: "5",
                        outlined: "",
                        shaped: "",
                      },
                    },
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-0 flex-grow-0",
                          attrs: { dark: "", color: "primary" },
                        },
                        [
                          _c("v-toolbar-title", { staticClass: "text-h6" }, [
                            _vm._v("Object Definitions"),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            { staticClass: "ml-2", attrs: { large: "" } },
                            [_vm._v("mdi-table-large")]
                          ),
                        ],
                        1
                      ),
                      _c("v-card-subtitle", { staticClass: "text-h6" }, [
                        _vm._v("Update existing Object definitions."),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            { attrs: { text: "", color: "primary accent-4" } },
                            [_vm._v("GO")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "5", xs: "5" } },
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "5", outlined: "", shaped: "" } },
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-0 flex-grow-0",
                          attrs: { dark: "", color: "primary" },
                        },
                        [
                          _c("v-toolbar-title", { staticClass: "text-h6" }, [
                            _vm._v("Object Relations"),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            { staticClass: "ml-2", attrs: { large: "" } },
                            [_vm._v("mdi-sitemap")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass: "text-h6",
                          attrs: { text: "", color: "primary" },
                        },
                        [
                          _vm._v("Create or Update existing Object Relation. "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                color: "primary accent-4",
                                to: "/object/relations",
                              },
                            },
                            [
                              _vm._v("Object Relation"),
                              _c("v-icon", [_vm._v("mdi-sitemap")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "text-h6" },
                        [
                          _vm._v(
                            "Create or Update existing Graph Edge Relations "
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                color: "primary accent-4",
                                to: "/object/graphEdges",
                              },
                            },
                            [
                              _vm._v("Graph Edge"),
                              _c("v-icon", [_vm._v("mdi-map")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-6", attrs: { justify: "space-around" } },
            [
              _c(
                "v-col",
                { attrs: { md: "5", xs: "5" } },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        to: "/products",
                        elevation: "5",
                        outlined: "",
                        shaped: "",
                      },
                    },
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-0 flex-grow-0",
                          attrs: { dark: "", color: "primary" },
                        },
                        [
                          _c("v-toolbar-title", { staticClass: "text-h6" }, [
                            _vm._v("Product Design Specification (PDS)"),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            { staticClass: "ml-2", attrs: { large: "" } },
                            [_vm._v("mdi-sitemap")]
                          ),
                        ],
                        1
                      ),
                      _c("v-card-subtitle", { staticClass: "text-h6" }, [
                        _vm._v("Create or update existing Product designs."),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            { attrs: { text: "", color: "primary accent-4" } },
                            [_vm._v("GO")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "5", xs: "5" } },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        href: "https://app.powerbi.com/groups/me/reports/95073be8-6c82-48f4-8e05-89f8efcd058b/ReportSection9dea5769876bf83e5e67?ctid=f66fae02-5d36-495b-bfe0-78a6ff9f8e6e",
                        target: "_blank",
                        elevation: "5",
                        outlined: "",
                        shaped: "",
                      },
                    },
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-0 flex-grow-0",
                          attrs: { dark: "", color: "primary" },
                        },
                        [
                          _c("v-toolbar-title", { staticClass: "text-h6" }, [
                            _vm._v("Power BI Design App Reports"),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            { staticClass: "ml-2", attrs: { large: "" } },
                            [_vm._v("mdi-chart-box-outline")]
                          ),
                        ],
                        1
                      ),
                      _c("v-card-subtitle", { staticClass: "text-h6" }, [
                        _vm._v("View Power BI Reports."),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            { attrs: { text: "", color: "primary accent-4" } },
                            [
                              _vm._v("GO "),
                              _c("v-icon", [_vm._v("mdi-open-in-new")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-6", attrs: { justify: "space-around" } },
            [
              _vm.isUserAdmin || _vm.isDataAdmin
                ? _c(
                    "v-col",
                    { attrs: { md: "5", xs: "5" } },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            to: "/admin",
                            elevation: "5",
                            outlined: "",
                            shaped: "",
                          },
                        },
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "mb-0 flex-grow-0",
                              attrs: { dark: "", color: "primary" },
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticClass: "text-h6" },
                                [_vm._v("Admin")]
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-2", attrs: { large: "" } },
                                [_vm._v("mdi-cog")]
                              ),
                            ],
                            1
                          ),
                          _c("v-card-subtitle", { staticClass: "text-h6" }, [
                            _vm._v("Administration of users and data."),
                          ]),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    color: "primary accent-4",
                                  },
                                },
                                [_vm._v("GO")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { md: "5", xs: "5" } },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        to: "/subjectareas",
                        elevation: "5",
                        outlined: "",
                        shaped: "",
                      },
                    },
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-0 flex-grow-0",
                          attrs: { dark: "", color: "primary" },
                        },
                        [
                          _c("v-toolbar-title", { staticClass: "text-h6" }, [
                            _vm._v("Subject Area"),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            { staticClass: "ml-2", attrs: { large: "" } },
                            [_vm._v("mdi-cog")]
                          ),
                        ],
                        1
                      ),
                      _c("v-card-subtitle", { staticClass: "text-h6" }, [
                        _vm._v(
                          "Create or Update existing Subject Area and mappings for it."
                        ),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            { attrs: { text: "", color: "primary accent-4" } },
                            [_vm._v("GO")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-6", attrs: { justify: "space-around" } },
            [
              _c(
                "v-col",
                { attrs: { md: "5", xs: "5" } },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        target: "_blank",
                        elevation: "5",
                        outlined: "",
                        shaped: "",
                      },
                    },
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-0 flex-grow-0",
                          attrs: { dark: "", color: "primary" },
                        },
                        [
                          _c("v-toolbar-title", { staticClass: "text-h6" }, [
                            _vm._v("Useful Links"),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            { staticClass: "ml-2", attrs: { large: "" } },
                            [_vm._v("mdi-domain")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-list",
                                    { staticClass: "mx-0" },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        color:
                                                          "primary accent-4",
                                                        href: "https://ulatlprod01.atlassian.net/wiki/spaces/DAAP/overview",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "DAAP - Confluence"
                                                      ),
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-message-text"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        { attrs: { exact: "" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        color:
                                                          "primary accent-4",
                                                        href: "https://apps.powerapps.com/play/e/6bcb827d-4c05-4238-ac07-ee723640bf81/a/61da9958-147b-4134-ac75-71628317254e?tenantId=f66fae02-5d36-495b-bfe0-78a6ff9f8e6e&sourcetime=2023-08-03%2009%3A53%3A22Z",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Platform Management"
                                                      ),
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-open-in-new"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        { attrs: { exact: "" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        color:
                                                          "primary accent-4",
                                                        href: "https://apps.powerapps.com/play/e/6bcb827d-4c05-4238-ac07-ee723640bf81/a/35d49c61-a06c-4632-8a1d-c94119ea4c23?tenantId=f66fae02-5d36-495b-bfe0-78a6ff9f8e6e&sourcetime=2023-08-03%2009%3A54%3A15Z",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Data Governance - Ownership & Accountability"
                                                      ),
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-open-in-new"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }