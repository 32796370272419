var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showArrows
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "7" } },
                [
                  _c(
                    "v-stage",
                    {
                      ref: "titleRef",
                      attrs: { config: { height: 150, width: 800 } },
                    },
                    [
                      _c(
                        "v-layer",
                        [
                          _vm.dataLength > 0
                            ? _c("v-text", {
                                attrs: {
                                  config: {
                                    text: _vm.subjectAreaName,
                                    x: 10,
                                    y: 0,
                                    padding: 10,
                                    fill: "blue",
                                    fontSize: 21,
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.dataLength > 0
                            ? _c("v-text", {
                                attrs: {
                                  config: {
                                    text: `LAST UPDATED: ${_vm.date} - GMT`,
                                    x: 10,
                                    y: 35,
                                    padding: 10,
                                    fill: "black",
                                    fontSize: 15,
                                    fontFamily: "Roboto, sans-serif",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.floatingTablesDataLength > 0
                            ? _c("v-rect", {
                                attrs: { config: _vm.buttonConfig },
                                on: {
                                  mouseenter: _vm.showPointerEnter,
                                  mouseleave: _vm.showPointerLeave,
                                  click: _vm.showHideFloatingTablesInner,
                                },
                              })
                            : _vm._e(),
                          _vm.floatingTablesDataLength > 0
                            ? _c("v-text", {
                                attrs: {
                                  config: {
                                    ..._vm.textConfigForFloating,
                                    x: 30,
                                    text: `FLOATING OBJECTS: ${_vm.floatingTablesDataLength}`,
                                  },
                                },
                                on: {
                                  mouseenter: _vm.showPointerEnter,
                                  mouseleave: _vm.showPointerLeave,
                                  click: _vm.showHideFloatingTablesInner,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "justify-center", attrs: { md: "3" } },
                [
                  _c(
                    "v-stage",
                    {
                      ref: "titleRefForLegendsLine",
                      attrs: { config: { height: 150, width: 200 } },
                    },
                    [
                      _c(
                        "v-layer",
                        _vm._l(
                          Object.keys(_vm.lineLegendData),
                          function (item, indexForLineLegends) {
                            return _c(
                              "div",
                              { key: indexForLineLegends },
                              [
                                _c("v-arrow", {
                                  attrs: {
                                    config: {
                                      points:
                                        _vm.getPoints(indexForLineLegends),
                                      fill: "black",
                                      stroke: "black",
                                      strokeWidth: 4,
                                      pointerWidth: 12,
                                      pointerLength: 12,
                                      dash: [8, 8],
                                      dashEnabled: indexForLineLegends === 1,
                                    },
                                  },
                                }),
                                _c("v-text", {
                                  attrs: {
                                    config: _vm.getLegendsConfig(
                                      indexForLineLegends,
                                      _vm.lineLegendData[item],
                                      true
                                    ),
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "padding-left-nill", attrs: { md: "2" } },
                [
                  _vm.showArrows
                    ? _c(
                        "v-stage",
                        {
                          ref: "titleRefForLegends",
                          attrs: { config: { height: 150, width: 400 } },
                        },
                        [
                          _c(
                            "v-layer",
                            _vm._l(
                              Object.keys(_vm.legendData),
                              function (item, indexForLegends) {
                                return _c(
                                  "div",
                                  { key: indexForLegends },
                                  [
                                    _c("v-rect", {
                                      attrs: {
                                        config: {
                                          x: 0,
                                          y:
                                            indexForLegends === 0
                                              ? 0
                                              : indexForLegends * 25,
                                          height: 20,
                                          width: 20,
                                          fill: _vm.legendData[item],
                                        },
                                      },
                                    }),
                                    _c("v-text", {
                                      attrs: {
                                        config: _vm.getLegendsConfig(
                                          indexForLegends,
                                          item
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }