
    import { Component, Provide, Vue, Watch, ProvideReactive } from 'vue-property-decorator'
    import ObjectRelationAttributeRow from '@/components/ObjectRelationAttributeRow.vue'
    import store from '@/store'
    import { ComponentBase } from '@/models/ComponentBase'
    import { DataObjectList } from '@/models/DataObjectList';
    import { ObjectRelationListItem } from '@/models/ObjectRelationListItem';
    import { ObjectRelation } from '@/models/ObjectRelation';
    import { ObjectRelationAttribute } from '@/models/ObjectRelationAttribute';
    import { ObjectRelationLookupData } from '@/models/ObjectRelationLookupData';
    import { ObjectRelationFilterData } from '@/models/ObjectRelationFilterData';
    import { ObjectRelationFilterOptions } from '@/models/ObjectRelationFilterOptions';
    import { DropdownOptionIntegerId } from '@/models/DropdownOptionIntegerId'
    import { DataOptions } from 'vuetify/types'
    import { selectedCertifyRelationships } from '@/models/selectedCertifyRelationships';



    @Component({
        components: {
            ObjectRelationAttributeRow
        }
    })
    export default class ObjectRelations extends ComponentBase {

        public headerClass = "text-uppercase text-subtitle-1 primary--text font-weight-bold";
        public cellClass = "text-subtitle-1 primary--text";
        public totalItems = 0;
        public currentTotalItems=0;
        public NoOfRelationshipsChanged=0;
        public selectedAll=false;
        public isRelationshipStatusChanged=false;
        public totalChangedRelationships=0;

        public items: Array<ObjectRelationListItem> = [];
        // The editedItem object is initialised here so that vue-property-decorator makes it an observable @data property
        // any reassigning of whole object needs to be done like this: Object.assign({}, x); so that the observable object is not replaced
        public editedItem: ObjectRelation = new ObjectRelation();

        @ProvideReactive()
        public objectRelationAttributes: Array<ObjectRelationAttribute> = [];
        public lookupData: ObjectRelationLookupData = new ObjectRelationLookupData();
        public filteredLookupData: ObjectRelationFilterData = new ObjectRelationFilterData();
        public filterOptions: ObjectRelationFilterOptions = new ObjectRelationFilterOptions();
        public selectedRelationships:Array<selectedCertifyRelationships>=[];
      


        @ProvideReactive()
        public primaryAttributeLookup: Array<DropdownOptionIntegerId> = [];
        @ProvideReactive()
        public foreignAttributeLookup: Array<DropdownOptionIntegerId> = [];

        private apiUrlBase = "/api/objectrelations/";
   

        private timerId: number;
        private loading = true;
        private overlay = false;
        private attrOverlay = false;


        public options: DataOptions = {
            page: 1,
            sortBy: ["loadDateText", "objectRelationName"],
            itemsPerPage: 20,
            sortDesc: [true, false],
            groupBy: [],
            groupDesc: [false],
            multiSort: true,
            mustSort: false
        };

        private isBatchLocked = false;
        public editDialog = false;
        public deleteDialog = false;
        public deleteCertifyDialog=false;
        public dqRiConfirmDialog = false;
        public editItemIsValid = false;
        public IsRelationValid = false;
        private isExisting = false;
        public relationTypeId = 0;

        private headers: Array<any> = [
            { text: "Object Relation", value: "objectRelationName", class: this.headerClass, cellClass: this.cellClass },
            {
            text: "Certify",
            value: "select",
            sortable: false,
            class: this.headerClass,
            cellClass: this.cellClass
        },
            { text: "Primary Object", value: "primaryTargetObjectName", class: this.headerClass, cellClass: this.cellClass },
            { text: "Foreign Object", value: "foreignTargetObjectName", class: this.headerClass, cellClass: this.cellClass },
            { text: "Last Updated", value: "loadDateText", class: this.headerClass, cellClass: this.cellClass },
            { text: "Actions", value: "actions", class: this.headerClass, cellClass: this.cellClass, sortable: false }
        ];


        private changeCertifiedRelationship(item:{id:number}){
            let itemSelected = this.selectedRelationships.filter(i=>i.objectRelationId==item.id)[0]
            if(itemSelected){
                    if(this.checkWhetherRelationChanged(itemSelected.baseRelationFlag,!itemSelected.relationCertifiedFlag)){
                        this.totalChangedRelationships+=1
                    }else{
                        this.totalChangedRelationships-=1;
                    }
                        itemSelected.relationCertifiedFlag=!itemSelected.relationCertifiedFlag;
                    }else{
                        if(this.checkWhetherRelationChanged(itemSelected.baseRelationFlag,true)){
                        this.totalChangedRelationships+=1
                    }
                        this.selectedRelationships.push({relationCertifiedFlag:false,objectRelationId:item.id})
                    }
                    this.isRelationshipStatusChanged=true;
        }
        private async getItems() {
            try {
                this.loading = true;
                if (this.filterOptions.relationTypeIds == 0) { this.filterOptions.relationTypeIds = 4; }
                await this.$axios.get<DataObjectList<ObjectRelationListItem>>(this.apiUrlBase, {
                    params: Object.assign(this.options, this.filterOptions)
                })
                    .then(response => {
                        this.items = response.data.items;
                        this.items.forEach(item=>{
                            if(item.allowUserCertification){
                                const itemToBePushed = {baseRelationFlag:item.relationCertifiedFlag,relationCertifiedFlag:item.relationCertifiedFlag,objectRelationId:item.id}
                                this.selectedRelationships.push(itemToBePushed)
                            }
                        })
                        this.totalItems = response.data.totalItems;
                        this.currentTotalItems = this.items.length; 
                        this.totalItems = response.data.totalItems;
                    })
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get list");
                        return Promise.reject(err);
                    });
            } finally {
                this.loading = false;
            }
        }
        private checkWhetherRelationChanged(currentVal:boolean,newVal:boolean){
            return currentVal!==newVal;
        }
        private changeRelationCertificationForItem(currentItem:any,item:any,relationCertificationFlag:boolean){
            const checkIfStatusChanged =this.checkWhetherRelationChanged(item.relationCertifiedFlag,relationCertificationFlag)
                        if(checkIfStatusChanged){
                            this.totalChangedRelationships+=1
                        }
                        if(currentItem[0]){
                                this.isRelationshipStatusChanged=checkIfStatusChanged;
                                currentItem[0].relationCertifiedFlag=relationCertificationFlag;
                                item.relationCertifiedFlag=relationCertificationFlag;      
                        }else{
                            if(checkIfStatusChanged){
                                this.isRelationshipStatusChanged=this.checkWhetherRelationChanged(item.relationCertifiedFlag,relationCertificationFlag); 
                                this.selectedRelationships.push({relationCertifiedFlag:relationCertificationFlag,objectRelationId:item.id})
                                item.relationCertifiedFlag=relationCertificationFlag;
                            }
                                
                        }
        }

        private changeCertificationPermissionForAll(){
            this.selectedAll=!this.selectedAll;
            this.totalChangedRelationships=0;
                this.items.forEach(item=>{
                    if(item.allowUserCertification){
                    let currentItem =this.selectedRelationships.filter(i=>i.objectRelationId==item.id); 
                    if(this.selectedAll){
                        this.changeRelationCertificationForItem(currentItem,item,true);  
                    }
                    else{
                        this.changeRelationCertificationForItem(currentItem,item,false);
                    }
                }        
                });
                }

        private async getItem(id: number) {
            await this.$axios.get<ObjectRelation>(this.apiUrlBase + id)
                .then(response => {
                    this.editedItem = Object.assign({}, response.data);
                    this.objectRelationAttributes = [...response.data.objectRelationAttributes]; // clone the array to the existing one to not lose the Vue change tracking
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get item");
                    return Promise.reject(err);
                });
        }

        private async getExistStatus() {
            if (this.editedItem.primaryTargetObjectId && this.editedItem.foreignTargetObjectId && this.editedItem.relationTypeId) {
                await this.$axios.get<boolean>(this.apiUrlBase + "exist-status", {
                    params: { pid: this.editedItem.primaryTargetObjectId, fid: this.editedItem.foreignTargetObjectId, rt: this.editedItem.relationTypeId, pobj: this.editedItem.primaryObjectType, fobj: this.editedItem.foreignObjectType }
                })
                    .then(response => {
                        this.isExisting = response.data;
                    })
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get existing status");
                        return Promise.reject(err);
                    });
            }
        }

        private async getFilteredLookupData() {
            if (this.filterOptions.relationTypeIds == null) {
                this.filterOptions.relationTypeIds = 4
            }
            await this.$axios.get<ObjectRelationFilterData>(this.apiUrlBase + "filter-data", {
                params: this.filterOptions
            }).then(response => {
                this.filteredLookupData = response.data;
            })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });
        }

        private async getLookupData(relationTypeId: number, PrimaryObjectType: string, ForeignObjectType: string) {
            await this.$axios.get<ObjectRelationLookupData>(this.apiUrlBase + "lookup-data", {
                params: { rt: relationTypeId, PrimaryObjType: PrimaryObjectType, ForeignObjType: ForeignObjectType }
            })
                .then(response => {
                    this.lookupData = response.data;
                })
                .catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });
        }

        private async getPrimaryAttributeLookupData(id: number) {
            if (this.editedItem.primaryObjectType == "TOB") {
                await this.$axios.get<Array<DropdownOptionIntegerId>>("/api/targetobjects/" + id + "/primary-attribute-lookup").then(response => {
                    this.primaryAttributeLookup = response.data;
                }).catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });
            }
            else {
                await this.$axios.get<Array<DropdownOptionIntegerId>>("/api/sourceobjects/" + id + "/primary-attribute-lookup").then(response => {
                    this.primaryAttributeLookup = response.data;
                }).catch(err => {
                    this.showErrorMessage(err, "Could not get lookup data");
                    return Promise.reject(err);
                });

            }
        }

        private async getForeignAttributeLookupData(id: number) {
            if (this.editedItem.foreignObjectType == "TOB") {
                await this.$axios.get<Array<DropdownOptionIntegerId>>("/api/targetobjects/" + id + "/foreign-attribute-lookup").then(response => {
                    this.foreignAttributeLookup = response.data;
                })
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get lookup data");
                        return Promise.reject(err);
                    });
            }
            else {
                await this.$axios.get<Array<DropdownOptionIntegerId>>("/api/sourceobjects/" + id + "/foreign-attribute-lookup").then(response => {
                    this.foreignAttributeLookup = response.data;
                })
                    .catch(err => {
                        this.showErrorMessage(err, "Could not get lookup data");
                        return Promise.reject(err);
                    });
            }
        }

        private createItem() {
            this.showOverlay();
            this.resetLookupData();
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                this.getLookupData(0, "TOB", "TOB")
                    .then(x => {
                        this.editedItem = Object.assign({}, new ObjectRelation());
                        this.editDialog = true;
                    }).finally(() => {
                        this.hideOverlay();
                    });
            });
        }

        private editItem(item: ObjectRelationListItem) {
            this.showOverlay();
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                this.getLookupData(0, item.primaryObjectType, item.foreignObjectType)
                    .then(x => {
                        this.getItem(item.id) // must be fetched before setting attr lookup data
                            .then(x => {
                                this.getAttributeLookupData()
                                    .then(x => {
                                        this.editDialog = true;
                                    }).finally(() => {
                                        this.hideOverlay();
                                    });
                            });
                    });
            });
        }

        private async getAttributeLookupData() {
            if (this.editedItem.primaryTargetObjectId && this.editedItem.foreignTargetObjectId) {
                await this.getPrimaryAttributeLookupData(this.editedItem.primaryTargetObjectId).then(() => {
                    this.getForeignAttributeLookupData(this.editedItem.foreignTargetObjectId);
                });
            } else {
                this.resetLookupData();
            }
        }

        private loadPrimaryForeignLookup() {
            //this.editedItem.primaryTargetObjectId = null;
            //this.editedItem.foreignTargetObjectId = null;
            this.resetLookupData()

            this.getLookupData(this.editedItem.relationTypeId, this.editedItem.primaryObjectType, this.editedItem.foreignObjectType);
            this.setObjectRelationAttributes();
        }


        private resetLookupData() {
            this.primaryAttributeLookup = [];
            this.foreignAttributeLookup = [];
            this.objectRelationAttributes = [];
            if (this.editedItem.primaryObjectType == null || this.editedItem.relationTypeId != 5) { this.editedItem.primaryObjectType = "TOB"; }
            if (this.editedItem.foreignObjectType == null || this.editedItem.relationTypeId != 5) { this.editedItem.foreignObjectType = "TOB"; }
        }

        private async setObjectRelationAttributes() {
            this.showAttrOverlay();

            await this.getExistStatus();
            if (this.isExisting == false) {
                await this.getAttributeLookupData().then(x => {
                    var attrList = [];
                    for (var i = 0; i < this.primaryAttributeLookup.length; i++) {
                        let attr: ObjectRelationAttribute = {
                            objectRelationId: this.editedItem.objectRelationId,
                            objectRelationAttributeId: 0,
                            primaryTargetObjectAttributeId: this.primaryAttributeLookup[i].id,
                            foreignTargetObjectAttributeId: 0,
                            constantValue: "",
                            isConstantYN: false,
                            foreignAttributeType: "TOA"
                        };
                        attrList.push(attr);
                        // using a top level array because vue is not tracking changes to an array on the edit object
                        this.objectRelationAttributes = attrList;
                    }

                }).finally(() => {
                    this.hideAttrOverlay();
                });
            } else {
                this.hideAttrOverlay();
            }
        }

        private deleteItem(item: ObjectRelationListItem) {
            this.getItem(item.id)
                .then(x => {
                    this.deleteDialog = true;
                });
        }

        private async deleteItemConfirm() {
            this.closeDeleteDialog();
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    this.$axios.delete(this.apiUrlBase + this.editedItem.objectRelationId)
                        .then(response => {
                            this.showResponseFeedback(response);
                            this.getItemsFiltered();
                        })
                        .catch(err => {
                            this.showErrorMessage(err);
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            });
        }

        private openRelationsCertifyDialog(){
            this.deleteCertifyDialog=true;
        }
        private async saveRelationshipChanges(){
            this.deleteCertifyDialog=false;
            this.showOverlay();
            this.$axios.put(this.apiUrlBase+'certify',this.selectedRelationships).then(response=>{
                this.showResponseFeedback(response);
            }).catch(err=>{
                this.showErrorMessage(err);
            }).finally(()=>{
                this.hideOverlay();
            })
        }
        private async save() {
            this.editDialog = false; // not using closeEditDialog because we do not want to reset the lookup data before it is saved
            this.showOverlay();

            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            }).then(() => {
                if (this.isBatchLocked) {
                    this.hideOverlay();
                } else {
                    if (this.editItemIsValid) {
                        this.editedItem.objectRelationAttributes = this.objectRelationAttributes;

                        if (this.editedItem.objectRelationId > 0) {
                            this.$axios.put(this.apiUrlBase + this.editedItem.objectRelationId, this.editedItem)
                                .then(response => {
                                    this.showResponseFeedback(response);
                                    this.getItemsFiltered();
                                })
                                .catch(err => {
                                    this.showErrorMessage(err);
                                }).finally(() => {
                                    this.hideOverlay();
                                });
                        }
                        else {
                            this.$axios.post(this.apiUrlBase, this.editedItem)
                                .then(response => {
                                    this.showResponseFeedback(response);
                                    this.getItemsFiltered();
                                })
                                .catch(err => {
                                    this.showErrorMessage(err);
                                }).finally(() => {
                                    this.hideOverlay();
                                });
                        }
                    } else {
                        this.hideOverlay();
                    }
                }

            });
        }

        get showAttributeSelection() {
            return this.objectRelationAttributes.length > 0 && (!this.invalidAttributeCount);
        }

        get invalidAttributeCount() {
            if (this.foreignAttributeLookup && this.foreignAttributeLookup.length > 0 && this.primaryAttributeLookup && this.primaryAttributeLookup.length > 0) {
                return this.foreignAttributeLookup.length < this.primaryAttributeLookup.length;
            } else {
                return false;
            }
        }

        get isEditLocked() {
            return (this.editedItem && this.editedItem.objectRelationId > 0);
        }

        get isRelationLocked() {
            return (this.editedItem && this.editedItem.relationTypeId == null);
        }

        get isDQRI() {
            return (this.editedItem && this.editedItem.relationTypeId == 5);
        }

        get isDQRIHome() {
            return (this.filterOptions && this.filterOptions.relationTypeIds == 5);
        }

        private closeEditDialog() {
            this.resetLookupData();
            this.editDialog = false;
        }

        private closeDeleteDialog() {
            this.deleteDialog = false;
        }

        private closeCertifyDialog(){
            this.deleteCertifyDialog=false;
        }

        private cancelDqRi() {
            this.editedItem.hasDqri = false;
            this.dqRiConfirmDialog = false;
        }

        @Watch("editedItem.hasDqri")
        private showDqRiDialog() {
            if (this.editDialog && this.editedItem.hasDqri) {
                this.dqRiConfirmDialog = true;
            }
        }


        @Watch("options", { deep: true })
        public getItemsByOptions(val: string | DataOptions) {
            // cancel the delay
            clearTimeout(this.timerId);

            // delay on searching when user types
            this.timerId = setTimeout(() => {
                this.getItemsFiltered();
            }, 1500);
        }

        private getItemsFiltered() {
            store.dispatch("events/setBatchLock").then(() => {
                this.isBatchLocked = store.getters["events/isBatchLocked"];
            });

            this.showOverlay();
            this.getFilteredLookupData().then(x => {
                this.getItems().finally(() => {
                    this.hideOverlay();
                });
            });
        }

        private showOverlay() {
            this.overlay = true;
        }

        private hideOverlay() {
            this.overlay = false;
        }

        private showAttrOverlay() {
            this.attrOverlay = true;
        }

        private hideAttrOverlay() {
            this.attrOverlay = false;
        }

        constructor() {
            super();
        }
    }
