// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/UnileverShilling.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/UnileverShillingBold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/UnileverShillingItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./assets/UnileverShillingMedium.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "\n@font-face {\n    font-family: 'Unilever Shilling';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n    font-family: 'Unilever Shilling Bold';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    font-weight: bold;\n}\n@font-face {\n    font-family: 'Unilever Shilling Italic';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    font-style: italic;\n}\n@font-face {\n    font-family: 'Unilever Shilling Medium';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n    font-weight: 500;\n}\n.font--ul {\n    font-family: 'Unilever Shilling', sans-serif !important;\n}\n.font--ul-med {\n    font-family: 'Unilever Shilling Medium', sans-serif !important;\n}\n.font--ul-bold {\n    font-family: 'Unilever Shilling Bold', sans-serif !important;\n}\n.font--ul-italic {\n    font-family: 'Unilever Shilling Italic', sans-serif !important;\n}\n.font-large {\n    font-size: 25px;\n}\n", ""]);
// Exports
module.exports = exports;
