export class SourceObjectAttribute {
    SourceObjectAttributeId: number;
    SourceObjectAttributeName:string;
    DataTypeId: number;
    DataType: string;
    Description: string;
    foreignKey: boolean;
    nullable: boolean;
    personalIdentifiableInformationId: number;
    primaryKey: boolean;
    sourceObjectId: number;
    sourceNameAlternative: string;
    loadDate: string;
    refreshDate: string;
    dataTypeCheck: boolean;
    dataFormat: string;
    duplicateCheck: boolean;
    nullCheck: boolean;
    isEditable: boolean;
    requestType: string;
    requestTypeReason: string;
    mandatoryYN: boolean;
    auditAttribute: number;
    fkCombination: string;
}
