var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "platforms-edit" },
    [
      _c("v-breadcrumbs", {
        attrs: { items: _vm.breadcrumbItems },
        scopedSlots: _vm._u([
          {
            key: "divider",
            fn: function () {
              return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "text-h4 font-weight-bold text-left primary--text pb-4 float-left",
                    },
                    [_vm._v(_vm._s(_vm.platformNameTitle))]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mx-4 float-left",
                                      attrs: {
                                        fab: "",
                                        dark: "",
                                        "x-small": "",
                                        color: "primary",
                                        label: "Refresh",
                                      },
                                      on: { click: _vm.refresh },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "aria-hidden": "false",
                                        "aria-label": "Refresh",
                                        dark: "",
                                      },
                                    },
                                    [_vm._v("mdi-refresh")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Refresh")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "dashboard-card",
                    { attrs: { minHeight: "300" } },
                    [
                      _c("platforms-form", {
                        ref: "platformFormRef",
                        attrs: {
                          platformId: _vm.platformId,
                          formState: _vm.formState,
                        },
                        on: { setPlatformName: _vm.setPlatformName },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }