var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "not-found" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { md: "12", xs: "12" } }, [
                _c(
                  "h2",
                  { staticClass: "text-center", attrs: { color: "primary" } },
                  [_vm._v("Not Found")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", attrs: { color: "primary" } },
                  [_vm._v("Sorry that was not found.")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", attrs: { color: "primary" } },
                  [_vm._v("It may not exist or has since been deleted.")]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }