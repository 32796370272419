var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.diagramNotesData[0] &&
    _vm.diagramNotesData[0]?.diagramNotes !== null
    ? _c(
        "div",
        [
          _c(
            "v-alert",
            {
              attrs: {
                "close-text": "Close Alert",
                dark: "",
                dismissible: "",
                "close-icon": "mdi-minus-circle",
              },
              model: {
                value: _vm.alert,
                callback: function ($$v) {
                  _vm.alert = $$v
                },
                expression: "alert",
              },
            },
            [
              _c("h3", [_vm._v("Diagram Notes")]),
              _vm._l(_vm.diagramNotesData, function (item, indexForMetadata) {
                return _c("div", { key: indexForMetadata }, [
                  _c("pre", [_vm._v(_vm._s(item.diagramNotes))]),
                ])
              }),
            ],
            2
          ),
          !_vm.alert
            ? [
                _c(
                  "v-btn",
                  {
                    staticClass: "fab",
                    style: { width: "15vw", borderRadius: "2rem" },
                    attrs: {
                      color: "primary",
                      dark: "",
                      fab: "",
                      tile: "",
                      elevation: "2",
                    },
                    on: {
                      click: function ($event) {
                        _vm.alert = true
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("Diagram Notes")]),
                    _c("v-icon", [_vm._v("mdi-note-text")]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }